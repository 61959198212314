import React, { useState } from 'react';
import { Animated } from "react-animated-css";

const ToggleDescription = ({ desc }) => {
    const [showDesc, setShowDesc] = useState(false);
    const [visible, setVisible] = useState(false);
    return (
        <>
            <div className="col-1 SubCategory">
                <button className='btn'
                    onClick={() => {
                        setVisible(!visible);
                        setTimeout(() => {
                            setShowDesc(!showDesc)
                        }, 500)
                    }}>
                    <i className="fa fa-info-circle fa-2x" />
                </button>
            </div>
            {desc && showDesc &&
                <Animated animationIn="fadeIn" isVisible={visible} animationInDelay={200}>
                    <div className="col-12 ps-4">
                        <p className='shadow-sm bg-light p-2'>{desc}</p>
                    </div>
                </Animated>
            }
        </>
    )
}
export default ToggleDescription