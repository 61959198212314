import React, { useState } from 'react';
import StickySearch from './StickySearch';
import MobileHeader from './MobileHeader';
import AuthHeader from "./AuthHeader";
import ScrollTop from "../widgets/ScrollTop";
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Logo from '../../../dist/images/logo.svg';
import StickyHamburger from './StickyHamburger';

const SeconderyHeader = () => {
    const [navbar, setNavbar] = useState(false);
    const guest_token = localStorage.getItem("guest_token");
    const prt_token = localStorage.getItem("token");
    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }
    window.addEventListener('scroll', changeBackground);
    return (
        <>
            <ScrollTop />
            <header id="header" className="header-main">
                <div className="container-fluid navbarWrap">
                    <MobileHeader />
                    <Navbar expand="lg" className={navbar ? 'navbar active' : 'navbar d-lg-flex d-none'}>
                        <NavLink to="/home" className="logo">
                            <img src={Logo} alt="Logo-img" />
                        </NavLink>
                        <StickySearch className="sticky-search" />
                        <div className="sticky_humburger">
                            <StickyHamburger />
                        </div>
                        <Nav
                            className="ms-auto my-2 my-lg-0"
                            style={{ maxHeight: '100px' }}
                            navbarScroll
                        >
                            <ul className="primaryMenu sticky_navmenu">
                                {/* <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/">Home</NavLink></li> */}
                                <li><NavLink exact className="navItem" to="/system/guest-query/general" activeClassName="navItemActive">Contact Us</NavLink></li>
                                <li><NavLink exact className="navItem" to="/system/faq" activeClassName="navItemActive">FAQ</NavLink></li>
                                <li><NavLink exact className="navItem" to="/system/blog" activeClassName="navItemActive">Blog</NavLink></li>
                                {/* <li><NavLink exact className="navItem" to="/system/contact-us" activeClassName="navItemActive">Contact</NavLink></li> */}
                                <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/for-business">
                                    For Business
                                </NavLink></li>
                                <li><NavLink exact className="navItem" activeClassName="navItemActive" to="/for-guest">
                                    For Guest
                                </NavLink></li>
                                <li className="dropdownWrap"><span className="navItem">30 Day Trial</span>
                                    <ul className="submenu">
                                        <li><a href="https://business.chuzeday.com/auth/registration" target="_blank" rel="noreferrer">Business</a></li>
                                        {/* <li><NavLink to="/signup">Guests</NavLink></li> */}
                                    </ul>
                                </li>
                                {!guest_token &&
                                    <li className="dropdownWrap"><span className="navItem">Log In</span>
                                        <ul className="submenu">
                                            <li><a href={prt_token ? "https://business.chuzeday.com/home" : "https://business.chuzeday.com/auth/login"} target="_blank" rel="noreferrer">Business</a></li>
                                            <li><NavLink to="/guest-login">Guests</NavLink></li>
                                        </ul>
                                    </li>
                                }
                            </ul>
                            {guest_token &&
                                <AuthHeader />
                            }
                        </Nav>
                    </Navbar>
                </div>
            </header>
        </>
    )
}

export default SeconderyHeader
