import React from 'react';
import DateTimeModal from "./DateTimeModal";

const VettingCall = ({ name, closeDays, bID, checkAuth, closeDate, blockedTime, canList,
    getVetDateTime, video_datetime, bName }) => {
    return (
        <div className='text-center mt-4 venuItem'>
            <div className="mt-4">
                <p>Dear <b>{name}</b>, <b>{bName}</b> requires
                    that all new Guests complete a Video Consultation Call before accepting
                    your appointment.</p>
                <p>This is an Introduction to <b>{bName}</b> and will not be necessary again once your 1st Booking is complete. Please select a date and time before your planned Service Appointment for the Consultation.</p>
                <p>Thank you!</p>
                {video_datetime &&
                    <p>Introduction call, date and time: {video_datetime}</p>
                }
            </div>
            <div className="col-12 col-lg-8">
                <DateTimeModal
                    closeDays={closeDays}
                    bID={bID}
                    checkAuth={checkAuth}
                    closeDate={closeDate}
                    blockedTime={blockedTime}
                    canList={canList}
                    vetting={true}
                    getVetDateTime={getVetDateTime}
                    notification={false}
                />
            </div>
        </div>
    );
};

export default VettingCall;
