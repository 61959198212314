import React, { useState, useEffect } from 'react';
import demoService from '../../../dist/images/Services/customer-banner.png';
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import AddFavorite from "./AddFavourite";
import { useQuery } from '@apollo/client';

//API
import { SERVICE_LIST } from "../../../gql/Query";

const BusinessItems = ({ content }) => {
    const [serviceList, setServiceList] = useState({})
    const [seeServices, setSeeServices] = useState(false)
    // console.log("seeServices", seeServices)
    const { data: service_cat } = useQuery(SERVICE_LIST, {
        variables: {
            type: "business",
            business_id: parseInt(content?.id)
        }
    });

    useEffect(() => {
        if (service_cat) {
            setServiceList(service_cat?.service_categories);
            // console.log("service_cat.service_categories", service_cat?.service_categories);
        }
    }, [service_cat])
    // console.log("content", content)
    const imageBaseURL = "https://chuzeday.com/uploads/businessPhoto/";
    const maxLength = 80;
    return (
        <div className="col-md-4 vItemWrap"
            onMouseEnter={() => { setSeeServices(true) }}
            onMouseLeave={() => { setSeeServices(false) }}>
            <div className="venuItemWrap">
                <div className="venuItemImg position-relative">
                    <Link to={`/${content?.slug}`}>
                        <img src={(content?.thumbnail ? `${imageBaseURL}${content?.thumbnail}` : demoService)} alt={content?.thumbnail} />
                    </Link>
                    {
                        seeServices &&
                        <div className="position-absolute service-list">
                            {
                                serviceList?.length > 0 &&
                                serviceList.map((item) => (
                                    <span key={item.id} className='border-1 border p-2 text-white m-2'>{item.name}</span>
                                ))
                            }
                        </div>
                    }
                </div>
                <div className="venuItemInfo">
                    <Link className="venuTtemName" to={`/${content?.slug}`}><span>{content?.name}</span></Link>
                    {
                        content?.location?.length > maxLength ?
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 500, hide: 750 }}
                                overlay={(props) => (
                                    <Tooltip id="button-tooltip" {...props}>
                                        {content?.location}
                                    </Tooltip>
                                )}
                            >
                                <Link className="venuTtemLocation" to={`/${content?.slug}`}>
                                    <strong>{content?.location ? `${content?.location.substring(0, maxLength)}...` : ""}</strong>
                                </Link>
                            </OverlayTrigger> :
                            <Link className="venuTtemLocation" to={`/${content?.slug}`}>
                                <strong>{content?.location}</strong>
                            </Link>
                    }
                    <div className='ratingWrap'>
                        <span className="icon"><i className="fas fa-star"></i></span>
                        <span className="count">{content?.rating}</span>
                    </div>
                    {content?.slug &&
                        <Link to={`/${content?.slug}`} className="btn venuBtn btn-lg mt-2">Visit Now</Link>
                    }
                </div>
            </div>
            <AddFavorite id={content?.id} />
        </div>
    )
}

export default BusinessItems
