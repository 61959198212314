import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import MetaGenerator from '../widgets/MetaGenerator';
import Loader from './Loader';

//import { privacyPolicy } from "./systemVariables";

//API
import { PAGES } from '../../../gql/Query';


// const Description = ({ item }) => {
//     const [show, setShow] = useState(false);
//     return (
//         <>
//             <h2 className="my-4">{item?.title}
//                 <span
//                     className={show ? "ms-2 shadow-lg rounded-circle p-2 point-show" : "ms-2 shadow-lg rounded-circle p-2"}
//                     onClick={() => {
//                         setTimeout(() => {
//                             setShow(
//                                 !show
//                             )
//                         }, 300)
//                     }}
//                     style={{
//                         cursor: "pointer"
//                     }}
//                 >
//                     {show ? "➖" : "➕"}
//                 </span></h2>
//             {
//                 show &&
//                 <div className='privacy-policy'>{item?.description} </div>
//             }
//         </>
//     )
// }

const PrivacyPolicy = () => {
    const [pageContent, setPageContent] = useState();
    const { data, loading, error } = useQuery(PAGES, {
        variables: {
            slug: "privacy_policy"
        }
    });

    useEffect(() => {
        if (data) {
            setPageContent(data?.pages)
            console.log("data", data)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    return (
        <>
            <MetaGenerator
                title="Privacy policy"
            />
            {
                loading ?
                    <Loader /> :
                    !error && pageContent &&
                    <>
                        <h1 className='sectionHeading text-capitalize text-center'>{pageContent?.title}</h1>
                        <div className='text-break' dangerouslySetInnerHTML={{ __html: pageContent?.body }} />
                        {/* <p>Last Updated: September 2021</p>
            <p>This is the Privacy Policy for Chuzeday (“Privacy Policy”) and all Chuzeday Affiliate companies. When you use our apps and services, including Chuzeday.com and Chuzeday.com, you’re trusting us with your information. We understand this is a big responsibility and we work hard to protect your information and put you in control. This Privacy Policy explains how and why we collect and use personal information, and what we do to ensure it is kept private and secure and in compliance with the applicable laws.</p>
            <p>This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information. For the purposes of data protection law, we will be a controller of the personal information we hold about you. This means we make decisions about how and why your information is used, and have a legal duty to make sure that your rights are protected when we use it.</p>
            {
                privacyPolicy?.map((item) => (
                    <>
                        <Description item={item} />
                    </>
                ))
            } */}
                    </>
            }
        </>
    )
}

export default PrivacyPolicy
