import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

//API
import { PARTNER_FEATURE } from "../../../../../gql/Query"

export default function ProfileFeatures() {
    const imageBaseURL = "https://chuzeday.com/";
    const [features, setFeatures] = useState();
    const { data, error, loading } = useQuery(PARTNER_FEATURE);
    useEffect(() => {
        if (data) {
            setFeatures(data?.partner_features)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    return (
        <section id="profileFeatures" className='pb-5'>
            <div className="featuresInner mt-5">
                <div className="container">
                    <div className="heading">
                        {/* <h2>Your Time is Precious</h2> */}
                        <h2>
                            <span>
                                "Let's get you Saving Time, have your own Professional Web Presence and Secure your Payments in minutes!"
                            </span>
                        </h2>
                    </div>
                    <div className="row g-4">
                        {
                            loading ?
                                <div className='d-flex justify-content-center'>
                                    <Spinner animation="border" variant="primary" />
                                </div> :
                                features?.map((item) => {
                                    return (
                                        <Link to={`/business-feature/${item?.slug}`} key={item?.id} className="col-lg-4 col-md-6">
                                            <div className="featureCard">
                                                <img src={item?.image && `${imageBaseURL}${item?.image}`} alt="profile" />
                                                <h5>{item?.title}</h5>
                                            </div>
                                        </Link>
                                    )
                                })
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
