import React from 'react';
import fail from "../../../../dist/images/Payment/payment-fail.png";
import logo from "../../../../dist/images/logo.svg";
import { Link } from "react-router-dom";
import MetaGenerator from '../../widgets/MetaGenerator';

const PayFail = () => {
    return (
        <>
            <MetaGenerator
                title="Payment failed"
            />
            <div className="container pt-150">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-7 col-12 mx-auto payment-status">
                        <div className="card venuItem">
                            <div className="card-body text-center">
                                <div className="logo mb-5">
                                    <img className="mt-5 pt-5" src={logo} alt="brand-logo" />
                                </div>
                                <h3>Payment Failed</h3>
                                <div className="stage my-5">
                                    <div className="box bounce-5" />
                                    <img className="box bounce-5" src={fail} alt="payment" />
                                </div>
                                <Link to="/profile/appointments" className="venuBtn p-3">Check Appointments</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayFail
