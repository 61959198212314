import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import MetaGenerator from '../widgets/MetaGenerator';
import Loader from './Loader';
import BusinessAction from '../widgets/CallToAction/BusinessAction';

//API
import { PAGES } from '../../../gql/Query';

const About = () => {
    const [pageContent, setPageContent] = useState();
    const { data, loading, error } = useQuery(PAGES, {
        variables: {
            slug: "about_us"
        }
    });

    useEffect(() => {
        if (data) {
            setPageContent(data?.pages)
            console.log("data", data)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    return (
        <>
            <MetaGenerator
                title="About Us"
            />
            {
                loading ?
                    <Loader /> :
                    !error && pageContent &&
                    <>
                        <div className="row">
                            <div className="col-md-8">
                                <h1 className='sectionHeading text-capitalize text-center'>{pageContent?.title}</h1>
                                <div className='text-break' dangerouslySetInnerHTML={{ __html: pageContent?.body }} />
                            </div>
                            <div className="col-md-4 position-relative">
                                <div className="sticky-action">
                                    <BusinessAction />
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default About
