import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import demoService from '../../../dist/images/Services/customer-banner.png';
import loader from '../../../dist/images/chz-loader.gif';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import Footer from '../Footer/Footer';
import RatingLabel from "../widgets/RatingLabel";
import MetaGenerator from '../widgets/MetaGenerator';

//API 
import { MY_FAVOURITES } from "../../../gql/Query";
import { ADD_FAVOURITE } from "../../../gql/Mutation";
const Favorites = () => {
    const [paginate, setPaginate] = useState();
    const [pages, setPages] = useState(18)
    const { data, loading } = useQuery(MY_FAVOURITES, {
        variables: {
            count: pages,
            page: 1
        }
    });
    const LoadMoreList = () => {
        setPages(pages + 18);
        window.scrollTo(0, 1000);
        // console.log("pages", pages)
    }
    const imageBaseURL = "https://chuzeday.com/uploads/businessPhoto/";
    const maxLength = 40;
    useEffect(() => {
        if (data?.my_favorite_business?.data) {
            setPaginate(data?.my_favorite_business?.paginatorInfo?.total)
        }
    }, [data])

    const [save_businesses] = useMutation(ADD_FAVOURITE,
        {
            refetchQueries: [{
                query: MY_FAVOURITES, variables: { count: pages, page: 1 }
            }],
            awaitRefetchQueries: true,
        }
    );
    const submitFav = (id) => {
        save_businesses({
            variables: {
                type: 0,
                business_id: parseInt(id)
            }
        }).then((data) => {
            // console.log(data)
            toast.success("Removed from your favourite list!");
        })
    }
    return (
        <>
            {loading ?
                <>
                    <MetaGenerator
                        title="Loading"
                    />
                    <div className='loader_wrap'>
                        <img src={loader} alt="chuzeday" width={180} />
                    </div>
                </> :
                <>
                    <MetaGenerator
                        title="My favorites"
                    />
                    <section id="favorites" className="gray-bg pb-5" style={{ height: data?.my_favorite_business?.data?.length === 0 ? "100vh" : "max-content" }}>
                        <div className="container pr-pt-150 pb-60">
                            <div className="row gy-4">
                                <h2 className="sectionHeading mb-0">My favorites</h2>
                                {
                                    data?.my_favorite_business?.data?.length > 0 ?
                                        data?.my_favorite_business?.data?.map((itemUp) => (
                                            itemUp?.businesses?.map((item) => (
                                                <div className="col-lg-4 position-relative" key={item.id}>
                                                    <Link to={`/${item?.slug}`}>
                                                        <Card>
                                                            <Card.Img variant="top" src={(item?.thumbnail ? `${imageBaseURL}${item?.thumbnail}` : demoService)} alt={item?.thumbnail} />
                                                            <Card.Body>
                                                                <Card.Title>{item?.name}</Card.Title>
                                                                {
                                                                    item?.location?.length > maxLength ?
                                                                        <OverlayTrigger
                                                                            placement="bottom"
                                                                            delay={{ show: 500, hide: 750 }}
                                                                            overlay={(props) => (
                                                                                <Tooltip id="button-tooltip" {...props}>
                                                                                    {item?.location}
                                                                                </Tooltip>
                                                                            )}
                                                                        >
                                                                            <Card.Text>{item?.location ? `${item?.location.substring(0, 20)}...` : ""}</Card.Text>
                                                                        </OverlayTrigger> :
                                                                        <Card.Text>{item?.location}</Card.Text>
                                                                }
                                                                <div className="fav-rating d-flex align-items-center">
                                                                    <i className="fas fa-star"></i>
                                                                    <h6>{item.rating}<RatingLabel val={item.rating} /></h6>
                                                                    <span className="text-muted">{item.number_of_ratings} ratings</span>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Link>
                                                    <i onClick={() => { submitFav(item?.id) }} className="favrt-icon fas fa-heart addFavourite" />
                                                </div>
                                            ))
                                        )) :
                                        <div className='resultItem text-center'>
                                            <h2>You haven't added any favourite shop yet!</h2>
                                        </div>
                                }
                            </div>
                        </div>
                        {
                            paginate > 18 &&
                            <p className="primaryBtn nextBtn" onClick={LoadMoreList}> Next</p>
                        }
                    </section>
                </>
            }
            <Footer />
        </>
    )
}
export default Favorites