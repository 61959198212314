import React, { CSSProperties, useEffect, useState } from 'react';
import { Jutsu } from 'react-jutsu';
import { useParams, useHistory } from 'react-router-dom';
import pusher from "../../../pusher/pusher";
import { Modal } from "react-bootstrap";
import MetaGenerator from '../../widgets/MetaGenerator';
import AgoraUIKit, { layout } from 'agora-react-uikit'
import 'agora-react-uikit/dist/index.css'
import { useMutation } from '@apollo/client';
import { AGORA_ROOM_TOKEN } from '../../../../gql/Mutation';
const VideoCall = () => {
    const history = useHistory();
    const [show, setShow] = useState(true);
    const [endCall, setEndCall] = useState(false);
    const { room, userId, profileName } = useParams();
    // console.log("🚀 ~ file: VideoCall.js:16 ~ VideoCall ~ room:", room)
    // agora
    const [videoCall, setVideoCall] = useState(true)
    const [username, setUsername] = useState('')
    const [isHost, setHost] = useState(true)
    const [isPinned, setPinned] = useState(false);
    const [token, setToken] = useState('')
    const [agoraAccessToken] = useMutation(AGORA_ROOM_TOKEN);
    useEffect(() => {
        const fetchData = async () => {
            if (room) {
                const { data } = await agoraAccessToken({
                    variables: {
                        room_id: room,
                    },
                })
                setToken(data.agoraAccessToken.token)
                // console.log('access token', data.agoraAccessToken.token)
            }
        }

        fetchData()
    }, [agoraAccessToken, room]);
    useEffect(() => {
        console.log("user_id", userId)
        if (userId) {
            const channel = pusher?.subscribe(`video-call-end.${userId}`);
            channel.bind('video-call-end', function (data) {
                console.log("pusher response", data)
                if (data?.result?.message === "End Call") {
                    setEndCall(true);
                }
            });
        }
    }, []);
    return (
        <>
            {/* {show &&
                <>
                    <MetaGenerator title="Consultation video call" />
                    <Jutsu
                        containerStyles={{ width: "100%", height: "100vh" }}
                        roomName={room}
                        displayName={profileName.replace('+', ' ')}
                        loadingComponent={<p>loading ...</p>}
                        errorComponent={<p>Oops, something went wrong</p>}
                        configOverwrite={{
                            requireDisplayName: false,
                            prejoinPageEnabled: false,
                            enableInsecureRoomNameWarning: true,
                            enableClosePage: true,
                            brandingRoomAlias: "OnbarClass",
                            disableProfile: true,
                            disableRemoteMute: true,
                            enableFeaturesBasedOnToken: true,
                            remoteVideoMenu: {
                                disableKick: true
                            }
                        }}
                        interfaceConfigOverwrite={{
                            DEFAULT_REMOTE_DISPLAY_NAME: "OnBarClass",
                            TOOLBAR_BUTTONS: [
                                "microphone",
                                "camera",
                                "fullscreen",
                                "chat",
                                "raisehand",
                                "filmstrip",
                                "desktop"
                            ],
                            SHOW_JITSI_WATERMARK: false,
                            SHOW_BRAND_WATERMARK: false,
                            VIDEO_QUALITY_LABEL_DISABLED: true,
                            GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
                            APP_NAME: "OnBarClass",
                            NATIVE_APP_NAME: "OnBarClass",
                            PROVIDER_NAME: "OnBarClass",
                            CONNECTION_INDICATOR_DISABLED: true,
                            VERTICAL_FILMSTRIP: false,
                            REMOTE_THUMBNAIL_RATIO: 16 / 9,
                            FILM_STRIP_MAX_HEIGHT: 80
                        }}
                    />

                    <div className="d-flex justify-content-center">
                        <button
                            className='btn btn-danger btn-lg mt-4'
                            onClick={() => {
                                setShow(false);
                                history.push("/profile/appointments")
                            }}
                            style={{ position: "absolute", top: 0, right: "10px" }}
                        >
                            End
                        </button>
                    </div>
                </>
            } */}
            <div style={styles.container}>
                <div style={styles.videoContainer}>
                    <h1 style={styles.heading}>Chuzeday Video Vetting</h1>
                    {videoCall &&
                        <AgoraUIKit
                            rtcProps={{
                                appId: 'ef438dec0be54db487b237ffd4f7aa5d',
                                channel: room,
                                
                                token: null, // add your token if using app in secured mode
                                role: isHost ? 'host' : 'audience',
                                layout: isPinned ? layout.pin : layout.grid,
                                enableScreensharing: true,
                            }}
                            rtmProps={{ username: username || profileName, displayUsername: true }}
                            callbacks={{
                                EndCall: () => {
                                    setVideoCall(false)
                                    setEndCall(true)
                                    setShow(false)
                                    setHost(false)
                                    setPinned(false)
                                    setUsername('')
                                    history.push("/profile/appointments")
                                },
                            }}
                        />
                    }
                </div>
            </div>
            <Modal
                show={endCall}
                onHide={() => setEndCall(false)}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton><h2>Call Ended</h2> </Modal.Header>
                <Modal.Body className='text-center venuItem'>
                    <h3>
                        Business has ended your consultation video call.
                    </h3>
                    <button className='venuBtn btn btn-sm me-2 mt-2' onClick={() => {
                        setShow(false);
                        history.push("/profile/appointments")
                    }}>
                        Ok
                    </button>
                </Modal.Body>
            </Modal>
        </>
    )
}
const styles = {
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flex: 1,
        backgroundColor: '#007bff22',
    },
    heading: { textAlign: 'center', marginBottom: 0 },
    videoContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
}

export default VideoCall