import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import Loader from '../system/Loader';

//API
import { OFFBOARDING_QUESTIONS } from '../../../gql/Query';
import { GUEST_OFFBOARDING } from '../../../gql/Mutation';
import { toast } from 'react-toastify';

const DeleteAccount = () => {
    const [interfaceNum, setInterfaceNum] = useState(0);
    const [questions, setQuestions] = useState({});
    const [answer, setAnswer] = useState();
    const [email, setEmail] = useState("");
    const [agreementBox, setAgreementBox] = useState({
        box1: false,
        box2: false
    });

    const { data, error, loading } = useQuery(OFFBOARDING_QUESTIONS);
    const [guestOffBoardingRequest] = useMutation(GUEST_OFFBOARDING);


    const handleQuery = () => {
        setInterfaceNum(1)
    }
    const changeAgreement = (e) => {
        const { id } = e.target;
        setAgreementBox(val => {
            return { ...val, [id]: !val[id] }
        })
    }
    const confirmOffboarding = () => {
        if (answer && email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!(re.test(email))) {
                toast.error("Please enter a valid Email Address!");
            } else {
                guestOffBoardingRequest({
                    variables: {
                        answer,
                        email
                    }
                }).then(({ data }) => {
                    if (data) {
                        if (data.guestOffBoardingRequest?.status === 1) {
                            setInterfaceNum(3);
                            toast.success(data.guestOffBoardingRequest?.message);
                        } else {
                            toast.error(data.guestOffBoardingRequest?.message);
                        }
                    }
                }).catch(e => {
                    console.log(e)
                    toast.error("Internal server error!");
                })
            }
        } else {
            toast.error("Please enter email address of this account!")
        }
    }
    useEffect(() => {
        if (data) {
            //console.log("data.offboarding_question", data.offboarding_question)
            setQuestions(data.offboarding_question);
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    return (
        <div className="container g-profile-container pr-pt-150">
            <div className="row">
                <div className="col-md-8 col-12 mx-auto ">
                    {loading ?
                        <Loader />
                        :
                        <>
                            {!error &&
                                <Card className="edit-guest-profile-card font-chzday delete-account venuItem">
                                    {interfaceNum === 0 &&
                                        <>
                                            <h3 className='fw-bolder'>{questions.title}</h3>
                                            <div
                                                dangerouslySetInnerHTML={{ __html: questions.description }}
                                            />
                                            <ul className='mt-4'>
                                                {questions.questions && JSON.parse(questions.questions).map(item => (
                                                    <li key={item.id} className='my-2'>
                                                        <div className='d-flex'>
                                                            <input className='me-2' type="radio" id={item.id} name="question" onChange={() => setAnswer(item.id)} />
                                                            <div dangerouslySetInnerHTML={{ __html: item.title }} />
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/profile/my-profile"
                                                    className='me-2 btn venuBtn my-4 btn-sm'
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    disabled={answer ? false : true}
                                                    className='ms-2 btn venuBtn my-4 btn-sm'
                                                    onClick={handleQuery}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {interfaceNum === 1 &&
                                        <>
                                            <h3 className='fw-bolder'>Delete your Chuzeday account</h3>
                                            <p>
                                                This action will delete your account and you won't be able to retrieve it. Please confirm you understand by ticking the business statements:
                                            </p>
                                            <ul>
                                                <li>
                                                    <p> <input className='me-2' type="checkbox" id="box1" onChange={changeAgreement} />You won't be able to access your appointments or vouchers.</p>
                                                </li>
                                                <li>
                                                    <p> <input className='me-2' type="checkbox" id="box2" onChange={changeAgreement} />You will no longer be able to book appointments via Chuzeday Marketplace</p>
                                                </li>
                                            </ul>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/profile/my-profile"
                                                    className='me-2 btn venuBtn my-4 btn-sm'
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    disabled={(agreementBox?.box1 && agreementBox?.box2) ? false : true}
                                                    className='ms-2 btn venuBtn my-4 btn-sm'
                                                    onClick={() => { setInterfaceNum(2) }}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {interfaceNum === 2 &&
                                        <>
                                            <h3 className='fw-bolder'>Confirm you are happy to proceed</h3>
                                            <p>
                                                To confirm the action of permanently deleting your account, please verify your email first
                                            </p>
                                            <div className='chuez-modal'>
                                                <input type="email" className='form-control' onChange={(e) => { setEmail(e.target.value) }} />
                                            </div>
                                            <div className="d-flex justify-content-center mt-2">
                                                <Link
                                                    to="/profile/my-profile"
                                                    className='me-2 btn venuBtn my-4 btn-sm'
                                                >
                                                    Cancel
                                                </Link>
                                                <button
                                                    className='ms-2 btn venuBtn my-4 btn-sm'
                                                    onClick={confirmOffboarding}
                                                >
                                                    Confirm
                                                </button>
                                            </div>
                                        </>
                                    }
                                    {interfaceNum === 3 &&
                                        <div className='text-center'>Thank you for your time on Chuzeday, you're always welcome to return
                                        </div>
                                    }
                                </Card>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount