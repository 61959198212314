import React from 'react';
import BasePage from './BasePage';
import { toast } from "react-toastify";
export default function App() {
  //Toast configuration
  toast.configure({
    bodyClassName: "grow-font-size",
    theme: "colored",
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
  return (
    <BasePage />
  );
}