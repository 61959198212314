import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        setTimeout(function () {
            window.scrollTo(0, 1);
        }, 2);
    }, [pathname]);

    return null;
}