import React, { useState, useEffect } from 'react';
import { Animated } from "react-animated-css";
import moment from "moment";
import { Link } from "react-router-dom";
import demoService from '../../../../dist/images/Services/customer-banner.png';
import GetCurrency from "../../widgets/GetCurrency";

const AptRight = ({ item, toggleState, reschedulelModal,
    cancelModal, ratingModal, status, profileName }) => {

    const [country, setCountry] = useState("");
    const [currency, setcurrency] = useState("");
    // if (item) {
    //     console.log("item", item)
    // }
    //----------get currency----------
    const c = GetCurrency(country);
    useEffect(() => {
        if (item) {
            setCountry(item.business_info?.country)
        }
        if (country && c) {
            setcurrency(c);
        }
    }, [item, country, c]);
    const imageBaseURL = "https://chuzeday.com/uploads/businessPhoto/";

    return (
        <>
            <div className={item.id === toggleState ? "d-block" : "d-none"} key={item.id}>
                <Animated className='tab-content' animationIn="fadeInDown" animationOut="fadeOut" isVisible={true} animationInDelay={300} animationOutDelay={300}>
                    <div className="heading">
                        <div className="time">
                            <h2>{moment.unix(item?.date).utcOffset('+0000').format('llll')}</h2>
                            <div className="status">
                                <i className="far fa-check-circle"></i>
                                <span>{item.status}</span>
                            </div>
                            <div className="status status bg-white text-dark ms-2">
                                <i className={item.payment_status === "Unpaid" ? "far fa-money-bill-alt" : "fas fa-hand-holding-usd"}></i>
                                <span>{item.payment_status === "Upfront" ? "Partially Paid" : item.payment_status}</span>
                            </div>
                        </div>
                        <div className="desc">
                            <div className="row">
                                <div className="col-md-12 mb-5">
                                    <div className="left d-flex">
                                        <img src={item?.business_info?.thumbnail ? `${imageBaseURL}${item?.business_info?.thumbnail}` : demoService} alt={item.name} />
                                        <div className="info">
                                            <h4><Link className='text-white' to={`/${item?.business_info?.slug}`}>{item?.business_info?.name}</Link></h4>
                                            <p>{item?.business_info?.location}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-start">
                                        <div className="right">
                                            <a className="d-flex flex-column align-items-center" href={`https://www.google.com/maps?daddr=${encodeURI(item?.business_info?.location)}`} target="_blank" rel="noreferrer">
                                                <i className="far fa-location"></i>
                                                <span>Directions</span>
                                            </a>
                                        </div>
                                        {
                                            status === "upcoming" &&
                                            <>
                                                <div className="right" onClick={() => { reschedulelModal(item) }}>
                                                    <span className="d-flex flex-column align-items-center">
                                                        <i className="far fa-calendar"></i>
                                                        <span>Reschedule</span>
                                                    </span>
                                                </div>
                                                <div className="right" onClick={() => {
                                                    cancelModal(item.id, item?.room_id);
                                                }}>
                                                    <span className="d-flex flex-column align-items-center">
                                                        <i className="far fa-times-circle"></i>
                                                        <span>Cancel</span>
                                                    </span>
                                                </div>
                                                {item.payment_status === "Unpaid" && !item?.room_id && item?.sale_id &&
                                                    <Link to={`/invoice/${item?.sale_id}`} className="right">
                                                        <span className="d-flex flex-column align-items-center">
                                                            <i className="fa fa-file-text-o" />
                                                            <span>Show Invoice</span>
                                                        </span>
                                                    </Link>
                                                }
                                                {
                                                    item?.room_id && (item.status === "New" || item.status === "Reschedule") &&
                                                    <Link
                                                        to={`/video-vetting/${item?.room_id}/${item.user_id}/${profileName}`}
                                                        className="right"
                                                    >
                                                        <span className="d-flex flex-column align-items-center">
                                                            <i className="fas fa-video"></i>
                                                            <span>Consultation Video Call</span>
                                                        </span>
                                                    </Link>
                                                }
                                            </>
                                        }
                                        {
                                            status === "past" && item.status !== "Cancelled" && !item?.room_id &&
                                            <>
                                                {!item?.review_status &&
                                                    <div className="right">
                                                        <span className="d-flex flex-column align-items-center"
                                                            onClick={() => { ratingModal(item.id) }}>
                                                            <i className="far fa-star"></i>
                                                            <span>Rate Us</span>
                                                        </span>
                                                    </div>
                                                }
                                                {item.payment_status === "Paid" && !item?.room_id && item?.sale_id &&
                                                    <Link to={`/invoice/${item?.sale_id}`} className="right">
                                                        <span className="d-flex flex-column align-items-center">
                                                            <i className="fa fa-file-text-o" />
                                                            <span>Show Invoice</span>
                                                        </span>
                                                    </Link>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !item?.room_id &&
                        <div className="recipient-wrap">
                            <ul className="services-item">
                                {
                                    item.appointment_detail.map((pricing, i) => (
                                        <li key={i}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h6>{pricing?.service_pricing?.service_name}</h6>
                                                {pricing?.service_pricing?.special_price ?
                                                    <span>{`${currency}${pricing?.service_pricing?.special_price}`}</span> :
                                                    <span>{`${currency}${pricing?.service_pricing?.price}`}</span>
                                                }
                                            </div>
                                            <time className="text-muted d-block">{moment.unix(pricing?.time).utcOffset('+0000').format('llll')}</time>
                                            <time className="text-muted d-block">{`${pricing?.service_pricing?.duration}min`}</time>
                                        </li>
                                    ))
                                }
                            </ul>
                            <ul className="total-amount">
                                {item?.sub_total > 0 &&
                                    <li className="d-flex align-items-center justify-content-between">
                                        <span>Sub total</span>
                                        <span>{`${currency}${item.sub_total}`}</span>
                                    </li>
                                }
                                {
                                    item?.discount > 0 &&
                                    <li className="d-flex align-items-center justify-content-between">
                                        <span>Discount</span>
                                        <span>{`${currency}${item.discount}`}</span>
                                    </li>
                                }
                                {
                                    item?.business_info?.upfront_amount > 0 &&
                                    <li className="d-flex align-items-center justify-content-between">
                                        <span>Deposit Payment({item?.upfront_percent}%)</span>
                                        <span>{`${currency}${(item?.total_amount * item?.upfront_percent) / 100}`}</span>
                                    </li>
                                }
                                <li className="d-flex align-items-center justify-content-between">
                                    <h6>Total</h6>
                                    <h6>{`${currency}${item?.total_amount}`}</h6>
                                </li>
                            </ul>
                            <h6 className='text-center'>*vat included</h6>
                        </div>
                    }
                </Animated>
            </div>
        </>
    )
}

export default AptRight
