import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ListGroup } from "react-bootstrap";
import Loader from '../Loader';
import BusinessAction from '../../widgets/CallToAction/BusinessAction';

//API
import { BLOG } from '../../../../gql/Query';

const SingleBlog = () => {
    const imageBaseURL = "https://chuzeday.com/";
    const [singleBlog, setSingleBlog] = useState()
    const [relatedBlog, setRelatedBlog] = useState()
    const { slug } = useParams();
    const { data, loading, error } = useQuery(BLOG, {
        variables: {
            slug: slug
        }
    })
    useEffect(() => {
        if (data) {
            setSingleBlog(data?.blog?.blog)
            setRelatedBlog(data?.blog?.related_blogs)
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    return (
        <>
            {
                loading ?
                    <Loader /> :
                    (singleBlog &&
                        <div className="row single-blog">
                            <div className="col-md-8">
                                <div className="d-flex justify-content-center">
                                    <img className="img-fluid card p-0" alt={singleBlog?.title} src={`${imageBaseURL}${singleBlog?.image}`} />
                                </div>
                                <h1 className='sectionHeading text-center pl-0 my-4'>{singleBlog?.title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: singleBlog?.description }} />
                            </div>
                            <div className="col-md-4">
                                <BusinessAction />
                                {relatedBlog?.length > 0 &&
                                    <div className="related-blog mt-5">
                                        <ListGroup as="ul">
                                            <p className='bg-light text-center p-3 fw-bolder h2'>Related blogs</p>
                                            {
                                                relatedBlog?.map((item) => (
                                                    <ListGroup.Item as="li" key={item?.id}>
                                                        <Link to={`/system/blog/${item?.slug}`}>{item?.title}</Link>
                                                    </ListGroup.Item>
                                                ))
                                            }
                                        </ListGroup>
                                    </div>
                                }
                            </div>
                        </div>
                    )
            }
        </>
    )
}

export default SingleBlog