import { useEffect, useState } from 'react';
import Currency from './Currency';
const GetCurrency = (country) => {
    // console.log("country", country)
    const [currency, setCurrency] = useState("")

    //-----------------processing the country name--------------
    const processCountry = (c_name) => {
        const check = c_name.trim().split("-");
        var result
        // console.log(check?.length)
        if (check?.length > 1) {
            const word = c_name?.replace(/-/g, " ");
            const words = word?.split(" ");
            for (let i = 0; i < words?.length; i++) {
                words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
            }
            result = words.join(" ");
            // console.log("words.join()", words.join(" "))
        } else {
            var trim = c_name?.trim();
            result = trim.charAt(0).toUpperCase() + trim.slice(1);
        }
        return result
    }
    useEffect(() => {
        if (country) {
            (Currency.filter(item => item?.country === processCountry(country))).map(x => (
                setCurrency(x.symbol)
            ))
        }
    }, [country]);
    return currency;
}

export default GetCurrency
