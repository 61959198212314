import React from 'react';
import Logo from '../../../../dist/images/call-to-action/logo_guest.svg';
import Apk1 from '../../../../dist/images/banner-slider/1.png';
import Apk2 from '../../../../dist/images/banner-slider/2.png';
import { Link } from 'react-router-dom';

const GuestAction = () => {
    return (
        <div className='call-to-action call-to-action-guest'>
            <div className="action-top mx-auto text-center">
                <Link to="/signup">
                    <img src={Logo} alt="Chuzeday" className='action_logo' />
                    <div className='fw-bolder mt-3'>It's Free</div>
                    <button className='btn btn-lg bg-light mt-2 fw-bolder'>
                        Join Chuzeday
                    </button>
                </Link>
            </div>
            <div className="action-bottom text-center mx-auto mt-5">
                <img src={Logo} alt="Chuzeday" className='action_logo' />
                <div className='fw-bolder mt-3'>Download Our FREE APP</div>
                <div className='bottom-text'>Discover and Book Services</div>
                <div className="d-flex justify-content-center apk mt-2">
                    <a href="https://play.google.com/store/apps/details?id=com.chuzeday.chuzeday&pli=1" target='_blank'>
                        <img src={Apk1} alt="Google play" />
                    </a>
                    <a href="https://apps.apple.com/us/app/chuzeday/id1626262034" target='_blank'>
                        <img src={Apk2} alt="Apple play" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default GuestAction