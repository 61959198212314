import React, { useState, useEffect } from 'react'
import { Form, Modal, Button, InputGroup, Card, } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import Avatar from '../../../dist/images/guestlogin/blank.png';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment';
import { toast } from 'react-toastify';
import Stripe from '../../../dist/images/PayGateways/stripe.png';
import Revolut from '../../../dist/images/PayGateways/revolut.png';
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import GetIP from "../widgets/GetIP";
import MetaGenerator from '../widgets/MetaGenerator';

//API
import { PROFILE_INFO, CLIENT_REPORT } from "../../../gql/Query";
import { PROFILE_UPDATE, AUTH_LOGOUT, CALENDAR_DISCONNECT } from "../../../gql/Mutation";

export default function MyProfile() {
    const [emailNotf, setEmailNotf] = useState(true);
    const [code, setCode] = useState("IE");
    const userLocation = GetIP();
    const client = useApolloClient();
    const imageBaseURL = "https://chuzeday.com/uploads/guest/";
    const [imgUrl, setImgurl] = useState("");
    const [inputType, setInputType] = useState("text");
    const [reportCount, setReportCount] = useState({});
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dateOfBirth, setDateOfBirth] = useState();
    const [identify, setIdentify] = useState();
    const [isconnected, setIsConnected] = useState(false);
    const [profile, setProfile] = useState({
        first_name: "",
        last_name: "",
        email: "",
        photo: "",
        client_detail: {
            additional_mobile: "",
            dob: "",
            email_notification: true,
            gender: "0",
            address: "",
            suite: "",
            country_id: "",
            eir_code: ""
        }
    });
    const [profileView, setProfileView] = useState({});
    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        console.log("results", results)
        setProfile((preValue) => {
            return {
                ...preValue,
                client_detail: {
                    ...profile.client_detail,
                    eir_code: `${results[0]?.address_components[0].long_name}`,
                    address: `${results[0]?.address_components[1].long_name}, ${results[0]?.address_components[2].long_name}`,
                    country: `${results[0]?.formatted_address?.substring(results[0]?.formatted_address?.lastIndexOf(',') + 1)}`
                }
            }
        })
    };
    // if google calendar not properly connect show this text
    useEffect(() => {
        // Get the current URL
        const currentURL = window.location.href
        const url = new URL(currentURL)
        const message = url.searchParams.get('message')
        if (message) {
            toast.success(message) // You can use a toast library or custom notification component here
        }
    }, []) 
    // ------------------google calendar disconnect mutation----------
    const [disconnectGoogleAccount] = useMutation(CALENDAR_DISCONNECT)
    //---------------------client report------------------------
    const { data: reports } = useQuery(CLIENT_REPORT);

    //-------------------Profile Information-------------------
    const { data, error, refetch } = useQuery(PROFILE_INFO);
    // console.log(profile)
    useEffect(() => {
        if (data) {
            setProfileView(data.profileInformation)
            //console.log("data.profileInformation", data.profileInformation)
            setProfile({ ...data.profileInformation, client_detail: { ...data.profileInformation.client_detail, country_id: "1" } });
            setPhone(data.profileInformation.mobile);
            if (data.profileInformation?.client_detail?.additional_mobile) {
                setPhone2(data.profileInformation?.client_detail?.additional_mobile);
            }
            if (data.profileInformation?.client_detail?.dob) {
                setDateOfBirth(moment.unix(data.profileInformation?.client_detail?.dob).format("YYYY-MM-DD"));
            }
            setEmailNotf(data.profileInformation?.client_detail?.email_notification);
            setIdentify(data.profileInformation?.identify);
            setIsConnected(data.profileInformation?.google_calendar);
        }
        if (reports) {
            setReportCount(reports?.clientReport)
            // console.log("reports?.clientReport", reports?.clientReport)
        }
    }, [data, reports])

    //Edit Profile
    const [guestProfileUpdate] = useMutation(PROFILE_UPDATE, {
        refetchQueries: [{ query: PROFILE_INFO }],
        awaitRefetchQueries: true,
    });
    const InputProfile = (e) => {
        const { name, value } = e.target;
        if (name === "gender" || name === "address" || name === "suite" || name === "country") {
            setProfile((preValue) => {
                return {
                    ...preValue,
                    client_detail: { ...profile.client_detail, [name]: value }
                }
            })
        } else {
            setProfile((preValue) => {
                return {
                    ...preValue,
                    [name]: value
                }
            })
        }
    }
    const focusField = (fieldName) => {
        if (fieldName) {
            document.querySelector(`#${fieldName}`).focus();
            document.querySelector(`#${fieldName}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    // console.log("document.querySelector(`#dob`)", document.querySelector("#dob"));

    //----------------update mutation------------------
    const updateProfile = (status) => {
        const token = localStorage.getItem("guest_token");
        if (token) {
            guestProfileUpdate({
                variables: {
                    first_name: profile.first_name,
                    last_name: profile.last_name,
                    mobile: phone,
                    email: profile.email,
                    gender: profile.client_detail?.gender,
                    dob: dateOfBirth,
                    client_info: "",
                    address: profile.client_detail?.address,
                    email_notification: status === "notification" ? !emailNotf : emailNotf,
                    marketing_notification: false,
                    language: "",
                    additional_mobile: phone2 ? phone2 : "",
                    client_source: "Online",
                    display_booking: false,
                    photo: profile.photo ? profile.photo : "",
                    suite: profile.client_detail?.suite,
                    country: profile.client_detail?.country,
                    eir_code: profile.client_detail?.eir_code,
                }
            }).then(({ data }) => {
                // you can do something with the response here
                // console.log(data);
                if (data) {
                    // console.log(data)
                    if (data.guestProfileUpdate.status === 1) {
                        if (status === "edit") {
                            toast.success("Profile edited successfully!");
                            handleClose();
                        }
                    } else {
                        toast.error(data.guestProfileUpdate.message);
                    }
                }

            }).catch((err) => {
                console.log(err);
                toast.error("Internal server error!");
                if (err.graphQLErrors[0]?.message === "Unauthenticated.") {
                    localStorage.clear();
                    client.cache.reset();
                    setTimeout(() => {
                        history.push("/")
                    }, 100)
                }
            });
        } else {
            history.push("/")
        }
    }

    const submitProfile = (e) => {
        // console.log("profile------------------", profile)
        e.preventDefault()
        //Check if the required Input field is empty
        if (!profile.first_name) {
            focusField("firstName");
            toast.error("Please enter first name!");
        } else if (!profile.last_name) {
            focusField("lastName");
            toast.error("Please enter last name!");
        } else if (!phone) {
            focusField("phone");
            toast.error("Please enter mobile number!");
        } else if (!profile.email) {
            focusField("email");
            toast.error("Please enter email address!");
        } else {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!(re.test(profile.email))) {
                focusField("email");
                toast.error("Please enter a valid Email Address!");
            } else {
                // console.log("profile>>", profile)
                updateProfile("edit");
            }
        }
        // console.log("profile Information:", profile)
        // console.log("date", date)
    }

    //---------------------Image upload---------------
    const handleImage = (e) => {
        const reader = new FileReader();
        const files = e.target.files;
        const file = files[0];
        reader.readAsDataURL(file);
        reader.onload = () => {
            onLoad(reader.result);
            setProfile({ ...profile, photo: reader.result })
        };
    };
    const onLoad = (fileString) => {
        // console.log(fileString);
        setImgurl(fileString);
        setProfile({ ...profile, photo: JSON.stringify(fileString) });
    };

    //Logout
    const [logout] = useMutation(AUTH_LOGOUT);
    const logOut = () => {
        const token = localStorage.getItem("guest_token");
        if (token) {
            setTimeout(() => {
                localStorage.clear();
                client.cache.reset();
            }, 100)
            logout()
                .then((data) => {
                    if (data) {
                        // console.log(data);
                        history.push("/");
                    }
                })
                .catch(err => console.log(err))
        } else {
            history.push("/");
        }
    }

    //Show specific fields on click
    const showSpecField = (field) => {
        handleShow();
        setTimeout(() => { focusField(field); }, 100)
    }

    //---------Get country code from IP address-----------
    useEffect(() => {
        if (userLocation) {
            setCode(userLocation.country_code)
        }
        if (error) {
            console.log(error)
        }
    }, [userLocation, error])

    //------------------------------Toggle notification-------------------
    const toggleNotification = () => {
        setEmailNotf(!emailNotf)
        updateProfile("notification")
    }
    // google calendar connect and disconnect
    const handleConnectGoogleCalendar=()=>{
        if(Boolean(identify)){
            window.location.host.includes("localhost:3000") || window.location.host.includes("guest.chuzeday.teknotunes.com") ?
            window.location.replace(`https://backend.cwprojects.xyz/calendar/permission/${identify}`) :
            window.location.replace(`https://backend.chuzeday.com/calendar/permission/${identify}`)
        }else{
            toast.warning('Please Try Again');
        }
    }
    const handleDisconnectGoogleCalendar=()=>{
        disconnectGoogleAccount().then(({ data }) => {
            if (data.disconnectGoogleAccount) {
                toast.success(data.disconnectGoogleAccount?.message);
                refetch();
            }
        }).catch(({ error }) => {
            toast.error(error.disconnectGoogleAccount?.message);
        })
    }

    return (
        <>
            <MetaGenerator
                title={(profileView?.first_name && profileView?.last_name) ? `${profileView.first_name} ${profileView.last_name}` : "My Profile"}
            />
            <div className="container g-profile-container pr-pt-150">
                <Card className="edit-guest-profile-card">
                    <div className="d-md-flex justify-content-between">
                        <div className="d-flex flex-wrap">
                            <div className="me-5 mb-sm-0 mb-4 edit-avatar-wrap">
                                <img src={(profileView.photo ? `${imageBaseURL}${profileView?.photo}` : Avatar)} alt="profile" />
                            </div>
                            <div>
                                <div className="edit-pr-toolbar d-flex align-items-center">
                                    <h3>{`${profileView.first_name ? profileView.first_name : ""} ${profileView.last_name ? profileView.last_name : ""}`}</h3>
                                    <span className=""><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" className="mh-50px"><path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#1b7895"></path><path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white"></path></svg></span>
                                    <button className="btn edit-profile-btn" onClick={handleShow}>Edit Profile</button>
                                </div>
                                <div className="edit-pr-badge-wrap d-flex align-items-center">
                                    <div className="edit-pr-badge-item">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="fa fa-calendar"></i>
                                            <h2 className="mb-0">{reportCount?.total_appt}</h2>
                                        </div>
                                        <span className="text-muted">Appointments</span>
                                    </div>
                                    <div className="edit-pr-badge-item">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="fa fa-money-bill"></i>
                                            <h2 className="mb-0">{reportCount?.total_voucher}</h2>
                                        </div>
                                        <span className="text-muted">Vouchers</span>
                                    </div>
                                    <div className="edit-pr-badge-item me-0">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="fa fa-heart"></i>
                                            <h2 className="mb-0">{reportCount?.total_favourite}</h2>
                                        </div>
                                        <span className="text-muted">Favorites</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="edit-pr-logout" onClick={logOut}>
                            <button className="btn">Log out</button>
                        </div>
                    </div>
                </Card>
                <div className="row g-4">
                    <div className="col-md-7 col-12">
                        <div className="Profile-details edit-pr-inner-card">
                            <div className="edit-pr-heading">
                                <h5 className="mb-0">Profile Details</h5>
                            </div>
                            <div className="body">
                                <div className="row mb-3">
                                    <h6 className='col-5'>Name</h6>
                                    <div className="col-7">
                                        <span className="me-2">{profileView.first_name ? profileView.first_name : ""}</span>
                                        <span>{profileView.last_name ? profileView.last_name : ""}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <h6 className='col-5'>Mobile Number</h6>
                                    <div className="col-7">
                                        <span>{profileView.mobile}</span>
                                    </div>
                                    {!profileView.client_detail?.additional_mobile &&
                                        <div className="col-5 d-md-block d-none">
                                            <button type="button"
                                                onClick={() => { showSpecField("phone2") }}
                                                className="add-option-btn btn btn-light d-flex align-items-center">
                                                <i className="fa fa-plus-circle text-muted"></i>
                                                <span className="text-muted">Add More</span>
                                            </button>
                                        </div>
                                    }
                                </div>
                                {profileView.client_detail?.additional_mobile &&
                                    <div className="row mb-3">
                                        <h6 className='col-5'>Additional Mobile Number</h6>
                                        <div className="col-7">
                                            <span>{profileView.client_detail?.additional_mobile}</span>
                                        </div>
                                    </div>
                                }
                                <div className="row mb-3">
                                    <h6 className='col-5'>Email address</h6>
                                    <span className='col-7'>{profileView.email ? profileView.email : ""}</span>
                                </div>
                                <div className="row mb-3">
                                    <h6 className='col-5'>Date of birth</h6>
                                    <span className='col-7'>{profileView?.client_detail?.dob ?
                                        moment.unix(profileView?.client_detail?.dob).format("DD/MM/YYYY") : ""}</span>
                                </div>
                                <div className="row mb-3">
                                    <h6 className='col-5'>Gender</h6>
                                    <span className='col-7'>{profileView?.client_detail?.gender ? profileView.client_detail.gender : ""}</span>
                                </div>
                                <hr />
                                <div className="row mb-3">
                                    <h6 className='col-5'>Address</h6>
                                    <div className="col-7">
                                        <span>{profileView.client_detail?.address ? profileView.client_detail?.address : ""}</span>
                                    </div>
                                    <div className='col-md-5 d-none d-md-block'>
                                        <button type="button" onClick={() => { showSpecField("address") }}
                                            className="add-option-btn btn btn-light d-flex align-items-center">
                                            <i className="fa fa-plus-circle text-muted"></i>
                                            <span className="text-muted">Edit Address</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <h6 className='col-5'>Apt. or Suite</h6>
                                    <div className="col-7">
                                        <span>{profileView?.client_detail?.suite ? profileView.client_detail.suite : ""}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <h6 className='col-5'>Country</h6>
                                    <div className="col-7">
                                        <span>{profileView?.client_detail?.country}</span>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <h6 className='col-5'>EIR Code/Post code</h6>
                                    <div className="col-7">
                                        <span>{profileView?.client_detail?.eir_code ? profileView.client_detail.eir_code : ""}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-12">
                        <div className="edit-pr-inner-card">
                            {isconnected ? 
                                <div className="edit-pr-logout ms-4">
                                    <button
                                        className="btn mt-3"
                                        onClick={handleDisconnectGoogleCalendar}
                                    >Disconnect Google Calendar</button>
                                </div>:
                                <div className='ms-4'>
                                    <button 
                                        className="btn edit-profile-btn mt-3" 
                                        onClick={handleConnectGoogleCalendar}
                                    >Connect Google Calendar</button> 
                                </div>
                            }
                            <div className="edit-pr-heading">
                                <p className="text-muted mb-0">When you connect with your Google calendar you will get your booked appointments details on your google calendar.</p>
                            </div>
                        </div>
                        <div className="edit-pr-inner-card">
                            <div className="edit-pr-heading">
                                <h5 className="mb-0">We Accept</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-center pd-2rm">
                                <img className='stripe me-2' src={Stripe} alt="Stripe" />
                                <img className='revolut ms-2' src={Revolut} alt="Revolut" />
                                {/* <button type="button" className="add-option-btn btn btn-light d-flex align-items-center mt-3">
                                            <i className="fa fa-plus-circle text-muted"></i>
                                            <span className="text-muted">Add Payment Method</span>
                                        </button> */}
                            </div>
                        </div>
                        <div className="edit-pr-inner-card  my-4">
                            <div className="edit-pr-heading">
                                <h5 className="mb-0">Notifications</h5>
                            </div>
                            <div className="d-flex justify-content-between pd-2rm">
                                <div className="left">
                                    <p className="text-muted mb-0">If you want to get Notification , please allow. </p>
                                </div>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={emailNotf}
                                    onChange={toggleNotification}
                                />
                            </div>
                        </div>
                        <div className="edit-pr-inner-card">
                            <div className="edit-pr-heading">
                                <h5 className="mb-0">Account deletion</h5>
                            </div>
                            <div className="d-flex justify-content-between pd-2rm">
                                <div className="left">
                                    <p className="text-muted mb-0">Are you sure you want to leave?</p>
                                </div>
                                <Link className='text-danger' to="/profile/delete-account">Delete my account</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="info-item-footer text-center py-5">
                    <span className="span1">&copy; Chuzeday.com |</span>
                    <Link to="/system/booking-terms"> Booking terms</Link><span> | </span>
                    <Link to="/system/privacy-policy">Privacy policy</Link>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="chuez-modal font-chzday"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            submitProfile();
                        }
                    }} autocomplete="off">
                        <div className="modal-in-field">
                            <h3 className="input-section-heading mb-3">Basic Information</h3>
                            <div className="d-flex justify-content-center">
                                <div className="mb-sm-0 mb-4 edit-avatar-wrap">
                                    <img src={imgUrl ? imgUrl : (profile.photo ? `${imageBaseURL}${profile?.photo}` : Avatar)} alt="profile" />
                                    <i className="fas fa-pen"></i>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImage}
                                    />
                                </div>
                            </div>
                            <Form.Group className="mb-3">
                                <Form.Label>First Name<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter first name" value={profile.first_name} name="first_name" onChange={InputProfile} id="firstName" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Last Name<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" placeholder="Enter last name" value={profile.last_name} name="last_name" onChange={InputProfile} id="lastName" />
                            </Form.Group>
                            <Form.Group md={6} className="mb-3 pe-sm-3">
                                <Form.Label>
                                    Mobile Number<span className="text-danger">*</span>
                                </Form.Label>
                                <InputGroup className="Number d-block">
                                    <PhoneInput
                                        international
                                        defaultCountry={code}
                                        placeholder="Enter phone number"
                                        value={phone}
                                        onChange={setPhone}
                                        id="phone"
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group md={6} className="mb-3 pe-sm-3">
                                <Form.Label>
                                    Additional Mobile Number
                                </Form.Label>
                                <InputGroup className="Number d-block">
                                    <InputGroup className="Number d-block">
                                        <PhoneInput
                                            international
                                            defaultCountry={code}
                                            placeholder="Enter additional phone number"
                                            value={phone2}
                                            onChange={setPhone2}
                                            id="phone2"
                                        />
                                    </InputGroup>
                                </InputGroup>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Email address<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={profile.email} name="email" id="email" onChange={InputProfile} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Date of birth</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your date of birth"
                                    name="dob"
                                    onFocus={(e) => { e.target.type = 'date'; setInputType("date") }}
                                    onBlur={(e) => { e.target.type = 'text'; setInputType("text") }}
                                    value={dateOfBirth ? (inputType === "date" ? dateOfBirth : moment(dateOfBirth).format('DD/MM/YYYY')) : ""}
                                    onChange={(e) => { setDateOfBirth(e.target.value); }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select className="me-sm-2 form-control" id="gender" name="gender" value={profile.client_detail?.gender} onChange={InputProfile} >
                                    <option value="">Choose...</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Non Binary">Non-binary</option>
                                    <option value="Prefer Not To Say">Prefer Not To Say</option>
                                </Form.Select>
                            </Form.Group>
                            <hr />
                            <h3 className="input-section-heading mb-3">Edit Address</h3>

                            {/* -------------------------- suggestion from EIR ------------------------*/}
                            <div className="mb-3">
                                <Form.Label>EIR Code/Post code</Form.Label>
                                <PlacesAutocomplete
                                    value={profile.client_detail?.eir_code}
                                    onChange={(value) => {
                                        setProfile((preValue) => {
                                            return {
                                                ...preValue,
                                                client_detail: { ...profile.client_detail, eir_code: value }
                                            }
                                        })
                                    }}
                                    onSelect={handleSelect}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <>
                                            <input type="text"
                                                className="form-control location-input" {...getInputProps({ placeholder: "Enter your EIR code" })}
                                            />
                                            <div>
                                                {loading ? <ul><li className="bg-white">Loading...</li></ul> : null}
                                                {suggestions?.length > 0 &&
                                                    <ul>
                                                        {suggestions.map((suggestion) => {
                                                            const style = {
                                                                backgroundColor: suggestion.active ? "#FD8E7C" : "white",
                                                                color: suggestion.active ? "white" : "black",
                                                                cursor: "pointer"
                                                            };
                                                            return (
                                                                <li key={suggestion.placeId} className="recent-search-location d-flex align-items-center mb-2" {...getSuggestionItemProps(suggestion, { style })}>
                                                                    {suggestion.description}
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                }
                                            </div>
                                        </>
                                    )}
                                </PlacesAutocomplete>
                            </div>
                            <Form.Group className="mb-3">
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" placeholder="Enter your address" value={profile.client_detail?.address} name="address" id="address" onChange={InputProfile} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Apt. or Suite</Form.Label>
                                <Form.Control type="text" placeholder="Enter your apt. or suite address" value={profile.client_detail?.suite} name="suite" id="suite" onChange={InputProfile} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Form.Control type="text"
                                    placeholder="Enter your country name"
                                    value={profile.client_detail?.country} name="country" id="country"
                                    onChange={InputProfile} />
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                                <Form.Label>Country</Form.Label>
                                <Form.Select
                                    className="me-sm-2 form-control"
                                    value={profile.client_detail?.country}
                                    name="country_id"
                                    id="country_id"
                                    onChange={InputProfile}
                                >
                                    {(countryList && countryList.length > 0) &&
                                        countryList.map((country) => (
                                            <option key={country.id} value={country.id}>{country.name}</option>
                                        ))}
                                </Form.Select>
                            </Form.Group> */}
                        </div>
                        <div className="float-end">
                            <Button variant="secondary" className="btn-lg me-3" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="dark" className="btn-lg" onClick={submitProfile}>
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
