import React from 'react';
import SmHeader from "./SmHeader";

const MobileHeader = () => {
    return (

        <header className="d-block d-lg-none mobile-header container" id="header">
            <SmHeader />
        </header>
    );
}
export default MobileHeader;