const currency = [
    {
        "country": "Afghanistan",
        "symbol": "؋"
    },
    {
        "country": "Albania",
        "symbol": "ALL"
    },
    {
        "country": "Algeria",
        "symbol": "دج"
    },
    {
        "country": "American Samoa",
        "symbol": "$"
    },
    {
        "country": "Andorra",
        "symbol": "€"
    },
    {
        "country": "Angola",
        "symbol": "Kz"
    },
    {
        "country": "Anguilla",
        "symbol": "$"
    },
    {
        "country": "Antarctica",
        "symbol": "$"
    },
    {
        "country": "Antigua and Barbuda",
        "symbol": "$"
    },
    {
        "country": "Argentina",
        "symbol": "$"
    },
    {
        "country": "Armenia",
        "symbol": "֏"
    },
    {
        "country": "Aruba",
        "symbol": "ƒ"
    },
    {
        "country": "Australia",
        "symbol": "$"
    },
    {
        "country": "Austria",
        "symbol": "€"
    },
    {
        "country": "Azerbaijan",
        "symbol": "m"
    },
    {
        "country": "Bahamas",
        "symbol": "BSD"
    },
    {
        "country": "Bahrain",
        "symbol": "B$"
    },
    {
        "country": "Bangladesh",
        "symbol": "৳"
    },
    {
        "country": "Barbados",
        "symbol": "Bds$"
    },
    {
        "country": "Belarus",
        "symbol": "BYR"
    },
    {
        "country": "Belgium",
        "symbol": "€"
    },
    {
        "country": "Belize",
        "symbol": "$"
    },
    {
        "country": "Benin",
        "symbol": "CFA"
    },
    {
        "country": "Bermuda",
        "symbol": "$"
    },
    {
        "country": "Bhutan",
        "symbol": "Nu"
    },
    {
        "country": "Bolivia",
        "symbol": "Bs"
    },
    {
        "country": "Bosnia and Herzegovina",
        "symbol": "KM"
    },
    {
        "country": "Botswana",
        "symbol": "P"
    },
    {
        "country": "Bouvet Island",
        "symbol": "NOK"
    },
    {
        "country": "Brazil",
        "symbol": "R$"
    },
    {
        "country": "British Indian Ocean Territory",
        "symbol": "$"
    },
    {
        "country": "Brunei",
        "symbol": "B$"
    },
    {
        "country": "Bulgaria",
        "symbol": "Лв"
    },
    {
        "country": "Burkina Faso",
        "symbol": "CFA"
    },
    {
        "country": "Burundi",
        "symbol": "FBu"
    },
    {
        "country": "Cambodia",
        "symbol": "៛"
    },
    {
        "country": "Cameroon",
        "symbol": "FCFA"
    },
    {
        "country": "Canada",
        "symbol": "$"
    },
    {
        "country": "Cape Verde",
        "symbol": "$"
    },
    {
        "country": "Cayman Islands",
        "symbol": "$"
    },
    {
        "country": "Central African Republic",
        "symbol": "FCFA"
    },
    {
        "country": "Chad",
        "symbol": "FCFA"
    },
    {
        "country": "Chile",
        "symbol": "$"
    },
    {
        "country": "China",
        "symbol": "¥"
    },
    {
        "country": "Christmas Island",
        "symbol": "$"
    },
    {
        "country": "Cocos (Keeling) Islands",
        "symbol": "$"
    },
    {
        "country": "Colombia",
        "symbol": "$"
    },
    {
        "country": "Comoros",
        "symbol": "CF"
    },
    {
        "country": "Congo",
        "symbol": "FC"
    },
    {
        "country": "Cook Islands",
        "symbol": "$"
    },
    {
        "country": "Costa Rica",
        "symbol": "₡"
    },
    {
        "country": "Croatia",
        "symbol": "kn"
    },
    {
        "country": "Cuba",
        "symbol": "$"
    },
    {
        "country": "Cyprus",
        "symbol": "€"
    },
    {
        "country": "Czech Republic",
        "symbol": "Kč"
    },
    {
        "country": "Denmark",
        "symbol": "Kr"
    },
    {
        "country": "Djibouti",
        "symbol": "Fdj"
    },
    {
        "country": "Dominica",
        "symbol": "$"
    },
    {
        "country": "Dominican Republic",
        "symbol": "$"
    },
    {
        "country": "East Timor",
        "symbol": "$"
    },
    {
        "country": "Ecuador",
        "symbol": "$"
    },
    {
        "country": "Egypt",
        "symbol": "E£"
    },
    {
        "country": "El Salvador",
        "symbol": "SVC"
    },
    {
        "country": "England",
        "symbol": "£"
    },
    {
        "country": "Equatorial Guinea",
        "symbol": "FCFA"
    },
    {
        "country": "Eritrea",
        "symbol": "Nfk"
    },
    {
        "country": "Estonia",
        "symbol": "€"
    },
    {
        "country": "Ethiopia",
        "symbol": "Br"
    },
    {
        "country": "Falkland Islands",
        "symbol": "£"
    },
    {
        "country": "Faroe Islands",
        "symbol": "kr"
    },
    {
        "country": "Fiji Islands",
        "symbol": "FJ$"
    },
    {
        "country": "Finland",
        "symbol": "€"
    },
    {
        "country": "France",
        "symbol": "€"
    },
    {
        "country": "French Guiana",
        "symbol": "€"
    },
    {
        "country": "French Polynesia",
        "symbol": "₣"
    },
    {
        "country": "French Southern territories",
        "symbol": "€"
    },
    {
        "country": "Gabon",
        "symbol": "FCFA"
    },
    {
        "country": "Gambia",
        "symbol": "D"
    },
    {
        "country": "Georgia",
        "symbol": "GEL"
    },
    {
        "country": "Germany",
        "symbol": "€"
    },
    {
        "country": "Ghana",
        "symbol": "GH¢"
    },
    {
        "country": "Gibraltar",
        "symbol": "GIP"
    },
    {
        "country": "Greece",
        "symbol": "€"
    },
    {
        "country": "Greenland",
        "symbol": "Kr"
    },
    {
        "country": "Grenada",
        "symbol": "$"
    },
    {
        "country": "Guadeloupe",
        "symbol": "€"
    },
    {
        "country": "Guam",
        "symbol": "$"
    },
    {
        "country": "Guatemala",
        "symbol": "Q"
    },
    {
        "country": "Guinea",
        "symbol": "FG"
    },
    {
        "country": "Guinea-Bissau",
        "symbol": "CFA"
    },
    {
        "country": "Guyana",
        "symbol": "$"
    },
    {
        "country": "Haiti",
        "symbol": "G"
    },
    {
        "country": "Heard Island and McDonald Islands",
        "symbol": "$"
    },
    {
        "country": "Holy See (Vatican City State)",
        "symbol": "€"
    },
    {
        "country": "Honduras",
        "symbol": "L"
    },
    {
        "country": "Hong Kong",
        "symbol": "$"
    },
    {
        "country": "Hungary",
        "symbol": "Ft"
    },
    {
        "country": "Iceland",
        "symbol": "kr"
    },
    {
        "country": "India",
        "symbol": "₹"
    },
    {
        "country": "Indonesia",
        "symbol": "Rp"
    },
    {
        "country": "Iran",
        "symbol": "﷼"
    },
    {
        "country": "Iraq",
        "symbol": "ع.د"
    },
    {
        "country": "Ireland",
        "symbol": "€"
    },
    {
        "country": "Israel",
        "symbol": "₪"
    },
    {
        "country": "Italy",
        "symbol": "€"
    },
    {
        "country": "Ivory Coast",
        "symbol": "CFA"
    },
    {
        "country": "Jamaica",
        "symbol": "$"
    },
    {
        "country": "Japan",
        "symbol": "¥"
    },
    {
        "country": "Jordan",
        "symbol": "د.ا"
    },
    {
        "country": "Kazakhstan",
        "symbol": "₸"
    },
    {
        "country": "Kenya",
        "symbol": "/-"
    },
    {
        "country": "Kiribati",
        "symbol": "$"
    },
    {
        "country": "Kuwait",
        "symbol": "KD"
    },
    {
        "country": "Kyrgyzstan",
        "symbol": "Лв"
    },
    {
        "country": "Laos",
        "symbol": "₭"
    },
    {
        "country": "Latvia",
        "symbol": "LVL"
    },
    {
        "country": "Lebanon",
        "symbol": "ل.ل"
    },
    {
        "country": "Lesotho",
        "symbol": "LSL"
    },
    {
        "country": "Liberia",
        "symbol": "LRD"
    },
    {
        "country": "Libyan Arab Jamahiriya",
        "symbol": "LYD"
    },
    {
        "country": "Liechtenstein",
        "symbol": "CHF"
    },
    {
        "country": "Lithuania",
        "symbol": "LTL"
    },
    {
        "country": "Luxembourg",
        "symbol": "€"
    },
    {
        "country": "Macao",
        "symbol": "MOP"
    },
    {
        "country": "North Macedonia",
        "symbol": "MKD"
    },
    {
        "country": "Madagascar",
        "symbol": "MGF"
    },
    {
        "country": "Malawi",
        "symbol": "MWK"
    },
    {
        "country": "Malaysia",
        "symbol": "MYR"
    },
    {
        "country": "Maldives",
        "symbol": "MVR"
    },
    {
        "country": "Mali",
        "symbol": "XOF"
    },
    {
        "country": "Malta",
        "symbol": "€"
    },
    {
        "country": "Marshall Islands",
        "symbol": "$"
    },
    {
        "country": "Martinique",
        "symbol": "€"
    },
    {
        "country": "Mauritania",
        "symbol": "MRO"
    },
    {
        "country": "Mauritius",
        "symbol": "MUR"
    },
    {
        "country": "Mayotte",
        "symbol": "€"
    },
    {
        "country": "Mexico",
        "symbol": "MXN"
    },
    {
        "country": "Micronesia, Federated States of",
        "symbol": "$"
    },
    {
        "country": "Moldova",
        "symbol": "MDL"
    },
    {
        "country": "Monaco",
        "symbol": "€"
    },
    {
        "country": "Mongolia",
        "symbol": "MNT"
    },
    {
        "country": "Montserrat",
        "symbol": "$"
    },
    {
        "country": "Morocco",
        "symbol": "MAD"
    },
    {
        "country": "Mozambique",
        "symbol": "MZN"
    },
    {
        "country": "Myanmar",
        "symbol": "MMR"
    },
    {
        "country": "Namibia",
        "symbol": "NAD"
    },
    {
        "country": "Nauru",
        "symbol": "$"
    },
    {
        "country": "Nepal",
        "symbol": "NPR"
    },
    {
        "country": "Netherlands",
        "symbol": "€"
    },
    {
        "country": "Netherlands Antilles",
        "symbol": "ANG"
    },
    {
        "country": "New Caledonia",
        "symbol": "XPF"
    },
    {
        "country": "New Zealand",
        "symbol": "NZD"
    },
    {
        "country": "Nicaragua",
        "symbol": "NIO"
    },
    {
        "country": "Niger",
        "symbol": "XOF"
    },
    {
        "country": "Nigeria",
        "symbol": "NGN"
    },
    {
        "country": "Niue",
        "symbol": "NZD"
    },
    {
        "country": "Norfolk Island",
        "symbol": "$"
    },
    {
        "country": "North Korea",
        "symbol": "KPW"
    },
    {
        "country": "Northern Ireland",
        "symbol": "GBP"
    },
    {
        "country": "Northern Mariana Islands",
        "symbol": "$"
    },
    {
        "country": "Norway",
        "symbol": "NOK"
    },
    {
        "country": "Oman",
        "symbol": "OMR"
    },
    {
        "country": "Pakistan",
        "symbol": "PKR"
    },
    {
        "country": "Palau",
        "symbol": "$"
    },
    {
        "country": "Palestine",
        "symbol": null
    },
    {
        "country": "Panama",
        "symbol": "PAB"
    },
    {
        "country": "Papua New Guinea",
        "symbol": "PGK"
    },
    {
        "country": "Paraguay",
        "symbol": "PYG"
    },
    {
        "country": "Peru",
        "symbol": "PEN"
    },
    {
        "country": "Philippines",
        "symbol": "PHP"
    },
    {
        "country": "Pitcairn",
        "symbol": "NZD"
    },
    {
        "country": "Poland",
        "symbol": "PLN"
    },
    {
        "country": "Portugal",
        "symbol": "€"
    },
    {
        "country": "Puerto Rico",
        "symbol": "$"
    },
    {
        "country": "Qatar",
        "symbol": "QAR"
    },
    {
        "country": "Reunion",
        "symbol": "€"
    },
    {
        "country": "Romania",
        "symbol": "RON"
    },
    {
        "country": "Russian Federation",
        "symbol": "RUB"
    },
    {
        "country": "Rwanda",
        "symbol": "RWF"
    },
    {
        "country": "Saint Helena",
        "symbol": "SHP"
    },
    {
        "country": "Saint Kitts and Nevis",
        "symbol": "$"
    },
    {
        "country": "Saint Lucia",
        "symbol": "$"
    },
    {
        "country": "Saint Pierre and Miquelon",
        "symbol": "€"
    },
    {
        "country": "Saint Vincent and the Grenadines",
        "symbol": "$"
    },
    {
        "country": "Samoa",
        "symbol": "WST"
    },
    {
        "country": "San Marino",
        "symbol": "€"
    },
    {
        "country": "Sao Tome and Principe",
        "symbol": "STD"
    },
    {
        "country": "S$i Arabia",
        "symbol": "SAR"
    },
    {
        "country": "Scotland",
        "symbol": "£"
    },
    {
        "country": "Senegal",
        "symbol": "XOF"
    },
    {
        "country": "Serbia",
        "symbol": "RSD"
    },
    {
        "country": "Seychelles",
        "symbol": "SCR"
    },
    {
        "country": "Sierra Leone",
        "symbol": "SLL"
    },
    {
        "country": "Singapore",
        "symbol": "SGD"
    },
    {
        "country": "Slovakia",
        "symbol": "€"
    },
    {
        "country": "Slovenia",
        "symbol": "€"
    },
    {
        "country": "Solomon Islands",
        "symbol": "SBD"
    },
    {
        "country": "Somalia",
        "symbol": "SOS"
    },
    {
        "country": "South Africa",
        "symbol": "ZAR"
    },
    {
        "country": "South Georgia and the South Sandwich Islands",
        "symbol": "GBP"
    },
    {
        "country": "South Korea",
        "symbol": "₩"
    },
    {
        "country": "South Sudan",
        "symbol": "SSP"
    },
    {
        "country": "Spain",
        "symbol": "€"
    },
    {
        "country": "Sri Lanka",
        "symbol": "LKR"
    },
    {
        "country": "Sudan",
        "symbol": "SDG"
    },
    {
        "country": "Suriname",
        "symbol": "SRD"
    },
    {
        "country": "Svalbard and Jan Mayen",
        "symbol": "NOK"
    },
    {
        "country": "Swaziland",
        "symbol": "SZL"
    },
    {
        "country": "Sweden",
        "symbol": "SEK"
    },
    {
        "country": "Switzerland",
        "symbol": "CHF"
    },
    {
        "country": "Syria",
        "symbol": "SYP"
    },
    {
        "country": "Tajikistan",
        "symbol": "TJS"
    },
    {
        "country": "Tanzania",
        "symbol": "TZS"
    },
    {
        "country": "Thailand",
        "symbol": "THB"
    },
    {
        "country": "The Democratic Republic of Congo",
        "symbol": "CDF"
    },
    {
        "country": "Togo",
        "symbol": "XOF"
    },
    {
        "country": "Tokelau",
        "symbol": "NZD"
    },
    {
        "country": "Tonga",
        "symbol": "TOP"
    },
    {
        "country": "Trinidad and Tobago",
        "symbol": "TTD"
    },
    {
        "country": "Tunisia",
        "symbol": "TND"
    },
    {
        "country": "Turkey",
        "symbol": "TRY"
    },
    {
        "country": "Turkmenistan",
        "symbol": "TMT"
    },
    {
        "country": "Turks and Caicos Islands",
        "symbol": "$"
    },
    {
        "country": "Tuvalu",
        "symbol": "$"
    },
    {
        "country": "Uganda",
        "symbol": "UGX"
    },
    {
        "country": "Ukraine",
        "symbol": "UAH"
    },
    {
        "country": "United Arab Emirates",
        "symbol": "AED"
    },
    {
        "country": "United Kingdom",
        "symbol": "GBP"
    },
    {
        "country": "United States",
        "symbol": "$"
    },
    {
        "country": "United States Minor Outlying Islands",
        "symbol": "$"
    },
    {
        "country": "Uruguay",
        "symbol": "UYU"
    },
    {
        "country": "Uzbekistan",
        "symbol": "UZS"
    },
    {
        "country": "Vanuatu",
        "symbol": "VUV"
    },
    {
        "country": "Venezuela",
        "symbol": "VEF"
    },
    {
        "country": "Vietnam",
        "symbol": "VND"
    },
    {
        "country": "Virgin Islands, British",
        "symbol": "$"
    },
    {
        "country": "Virgin Islands, U.S.",
        "symbol": "$"
    },
    {
        "country": "Wales",
        "symbol": "£"
    },
    {
        "country": "Wallis and Futuna",
        "symbol": "XPF"
    },
    {
        "country": "Western Sahara",
        "symbol": "MAD"
    },
    {
        "country": "Yemen",
        "symbol": "YER"
    },
    {
        "country": "Zambia",
        "symbol": "ZMW"
    },
    {
        "country": "Zimbabwe",
        "symbol": "ZWD"
    }
]

// console.log(currency.length)
export default currency