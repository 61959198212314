import React from 'react'
import { useHistory } from 'react-router-dom'
import StickySearch from './StickySearch'
import BackToHome from '../../../dist/images/Login/colourLeft.png'

export default function MobileSearchForm() {
    const history = useHistory()
    const goToPreviousPath = () => {
        history.goBack()
    }
    return (
        <>
            <section id="MobileSearch" className="bg-light">
                <div className="container">
                    <div className="MobileSearchInner">
                        <span onClick={goToPreviousPath} className="BackHome">
                            <img src={BackToHome} alt="icon" />
                        </span>
                        <StickySearch />
                    </div>
                </div>
            </section>
        </>
    )
}
