import { Spinner } from "react-bootstrap";
import loader from '../../../dist/images/chz-loader.gif';

const Loader = () => {
    return (
        <div className='loader_wrap'>
            {/* <Spinner animation="border" variant="primary" /> */}
            <img
            src={loader}
            alt='loader'
            width={180}
            />
        </div>
    )
}

export default Loader