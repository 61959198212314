import React from 'react';
import logo from "../../../dist/images/logo.svg";
import { Link, useLocation } from "react-router-dom";
const QeurySentSuccess = () => {
    const location = useLocation();
    const { message } = location.state || {};
    console.log("🚀 ~ QeurySentSuccess ~ message:", message)
    return (
        <>
            <div className="container ">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-7 col-12 mx-auto payment-status">
                        <div className="card venuItem">
                            <div className="card-body text-center">
                                <h3 className='mt-5 pt-5'>{message}</h3>
                                <div className="logo mb-5">
                                    <img className="mt-5 pt-5" src={logo} alt="brand-logo" />
                                </div>
                                {/* <Link to="/profile/appointments" className="venuBtn p-3" >Check Appointments</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QeurySentSuccess
