import React from "react";
import Slider from "react-slick";
import demoService from '../../../../dist/images/banner-slider/chuzy-banner.jpg';

const ShopBaner = ({ content, loading }) => {
    const imageBaseURL = "https://chuzeday.com/uploads/businessPhoto/";
    // if (content?.length > 2) {
    //     console.log(content?.length)
    // } else {
    //     console.log("no images")
    // }
    const settings = {
        fade: true,
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 3000,
        autoplaySpeed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1
    }
    return (
        <div className="shopSlider">
            <Slider {...settings}>
                {
                    loading ?
                        <div className="banerimgWrap">
                            <img src={demoService} alt="shop" style={{ height: "100%" }} />
                        </div> :
                        (
                            content?.length > 2 ?
                                JSON.parse(content).map((item) => (
                                    <div className="banerimgWrap" key={item}>
                                        <img src={(item ? `${imageBaseURL}${item}` : demoService)} alt={item} />
                                    </div>
                                )) :
                                <div className="banerimgWrap">
                                    <img src={demoService} alt="shop" style={{ height: "100%" }} />
                                </div>
                        )
                }
            </Slider>
        </div>
    )
}

export default ShopBaner;