import React, { createContext, useState, useEffect } from 'react';
import ReviewsLeft from '../Reviews/ReviewsLeft';
import ReviewsRight from '../Reviews/ReviewsRight';
import { useQuery } from '@apollo/client';
import { Spinner } from "react-bootstrap"

//API
import { RATING_FILTER } from "../../../../../gql/Query";

export const RatingContext = createContext()

const Review = ({ rating, ratingNum, id }) => {
    const [ratingList, setRatingList] = useState({});
    const [ratingCount, setRatingCount] = useState({});
    const { data, loading, error } = useQuery(
        RATING_FILTER,
        {
            variables: {
                business_id: parseInt(id)
            }
        });
    useEffect(() => {
        if (data) {
            setRatingCount(data?.business_reviews?.rating);
            setRatingList(data?.business_reviews?.lists);
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    return (
        <RatingContext.Provider value={{ ratingList, setRatingList, ratingCount, rating, ratingNum }}>
            <section section id="Review" className="pt-5">
                <div className="container">
                    <h2 className="sectionHeading">Reviews</h2>
                    <div className="row">
                        <div className="col-lg-6">
                            <ReviewsLeft />
                        </div>
                        <div className="col-lg-6">
                            {
                                loading ?
                                    <div className='d-flex justify-content-center'>
                                        <Spinner size="lg" animation="border" variant="primary" />
                                    </div> :
                                    (data && <ReviewsRight />)
                            }
                        </div>
                    </div>
                </div>
            </section>
        </RatingContext.Provider>
    );
}

export default Review;