import React from 'react';
//import Dashboard from '../PartnerProfile/components/Dashboard/dashboard';
import ProfileFeatures from '../PartnerProfile/components/Features/ProfileFeatures';
import AppsImage from '../PartnerProfile/components/AppsImage/AppsImage';
import Partnerfooter from '../PartnerProfile/components/PartnerFooter/Partnerfooter';
import ScrollTop from '../widgets/ScrollTop';
import MetaGenerator from '../widgets/MetaGenerator';
import SeconderyHeader from '../Header/SeconderyHeader';
import BusinessOwner from './components/BusinessOwner';

const Profile = () => {
    return (
        <>
            <MetaGenerator
                title="Business dashboard demo"
            />
            <ScrollTop />
            <div id="filterHeader">
                <SeconderyHeader />
            </div>
            <div className="gray-bg bg-transparent">
                <ProfileFeatures />
                {/* <Dashboard /> */}
                <AppsImage />
                <BusinessOwner />
            </div>
            <Partnerfooter />
        </>
    );
};

export default Profile;