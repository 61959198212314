import React from 'react';
import Footer from '../../../../../frontend/modules/Footer/Footer';

export const Partnerfooter = () => {
    return (
        <>
            <div id="partnerFooter">
                <Footer/>
            </div>
        </>
    )
}
export default Partnerfooter;