import React from 'react'
import SubscriptionPayment from './SubscriptionPayment';
import { useParams } from 'react-router-dom';
import MetaGenerator from '../MetaGenerator';

const SubscriptionUrl = () => {
    let { publicId, amount, subscribedId, revolutId } = useParams();
    //console.log("publicId, amount, saleId, revolutId", publicId, amount, saleId, revolutId)
    amount = parseFloat(amount);
    subscribedId = parseFloat(subscribedId);
    return (
        <div className="container my-4">
            <MetaGenerator title="Revolut payment" />
            <SubscriptionPayment
                order={publicId}
                payment_amount={amount}
                subscribed_id={subscribedId}
                revolutId={revolutId}
            />
        </div>
    )
}

export default SubscriptionUrl