import React, { useState } from 'react'
import { Animated } from 'react-animated-css';
import T1 from '../../../../dist/images/singleShop/services/arrowdn.png';
import T2 from '../../../../dist/images/singleShop/services/arrowS.png';

const FAQ = ({ content }) => {
    const [visible, setVisible] = useState(false);
    const [show, setShow] = useState(false);
    const [openFaqId, setOpenFaqId] = useState(null); // Track open FAQ by ID
    
    //----------------------html to string-------------------
    const extractContent = (text) => {
        const span = document.createElement('span');
        span.innerHTML = text;
        return <p className='shadow-sm bg-light p-2'> {span.textContent} </p>
    };

    const toggleFaq = (faqId) => {
        // If the clicked FAQ is already open, close it. Otherwise, open it and close others.
        setOpenFaqId(openFaqId === faqId ? null : faqId);
    };

    return (
        <div id="shopServiceItem overflow-hidden">
            <div
                onClick={() => {
                    setVisible(!visible);
                    setTimeout(() => {
                        setShow(!show);
                    }, 500)
                }}
                className="category"
            >
                {show ? <img src={T1} alt="icon" /> : <img src={T2} alt="icon" />}
                <h5>{content?.name}</h5>
            </div>
            {show && (
                <Animated animationIn="fadeIn" isVisible={visible} animationInDelay={200} className='service_content'>
                    {(content?.faqs?.length > 0 &&
                        content?.faqs?.map((faq) => (
                            <div className="row mx-0 overflow-hidden" key={faq.id}>
                                <div
                                    onClick={() => toggleFaq(faq.id)}
                                    className="category"
                                >
                                    {openFaqId === faq.id ? <img src={T1} alt="icon" /> : <img src={T2} alt="icon" />}
                                    <div className="name">
                                        <span>{faq?.question}</span>
                                    </div>
                                </div>

                                {openFaqId === faq.id && (
                                    <Animated animationIn="fadeIn" isVisible={openFaqId === faq.id} animationInDelay={200} className='service_content text-wrap'>
                                        <div className="ps-4 faq_content"
                                            dangerouslySetInnerHTML={{ __html: faq?.answer }}
                                        />
                                    </Animated>
                                )}
                            </div>
                        ))
                    )}
                </Animated>
            )}
        </div>
    );
};

export default FAQ;
