import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { RatingContext } from "./Reviews";
import CountStar from "../../../widgets/CountStar"
import { Scrollbars } from "react-custom-scrollbars-2";

export default function ReviewsRight() {
    const ratingItem = useContext(RatingContext);
    const { ratingList } = ratingItem;

    useEffect(() => {
    }, []);
    return (
        <>
            {
                ratingList?.length > 8 &&
                <div className="reviewsRightLg">
                    <Scrollbars>
                        {
                            ratingList?.map((item) => (
                                <div className="Item Item5" key={item?.id}>
                                    <div className="pofileWrap">
                                        <div className="profile">
                                            <span className='text-capitalize'>{`${(item?.user_info?.first_name).charAt(0)}${(item?.user_info?.last_name).charAt(0)}`}</span>
                                        </div>
                                        <div className="comment">
                                            <h5>{`${item?.user_info?.first_name} ${item?.user_info?.last_name}`}</h5>
                                            <h6>{moment.unix(item?.created_at).format('MMMM Do YYYY')}</h6>
                                            {item?.comment && <p>{item?.comment}</p>}
                                        </div>
                                    </div>
                                    <div className="ratingWrap">
                                        <ul className="starWrap">
                                            <CountStar val={item?.rating} />
                                        </ul>
                                    </div>
                                </div>
                            ))
                        }
                    </Scrollbars>
                </div>
            }
            {
                ratingList?.length > 0 && ratingList?.length < 8 &&
                <div className="reviewsRightSm">
                    {
                        ratingList?.map((item) => (
                            <div className="Item Item5" key={item?.id}>
                                <div className="pofileWrap">
                                    <div className="profile">
                                        <span className='text-capitalize'>{`${(item?.user_info?.first_name).charAt(0)}${(item?.user_info?.last_name).charAt(0)}`}</span>
                                    </div>
                                    <div className="comment">
                                        <h5>{`${item?.user_info?.first_name} ${item?.user_info?.last_name}`}</h5>
                                        <h6>{moment.unix(item?.created_at).format('MMMM Do YYYY')}</h6>
                                        {item?.comment && <p>{item?.comment}</p>}
                                    </div>
                                </div>
                                <div className="ratingWrap">
                                    <ul className="starWrap">
                                        <CountStar val={item?.rating} />
                                    </ul>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </>
    )
}
