import React from "react";
import {
      BrowserRouter as Router,
      Switch,
      Route,
      // Redirect
} from "react-router-dom";
import Home from "./modules/Home/Home";
import GuestLogin from "./modules/Login/GuestLogin";
import SignUp from "./modules/Login/Signup";
import SocialRegister from "./modules/Login/SocialRegister";
import LoginMail from "./modules/Login/LoginWithMail";
import ForgotPass from "./modules/Login/ForgotPass";
import ResetPass from "./modules/Login/ResetPass";
import ResponsiveHeader from "./modules/Header/MobileHeader";
import MobileSearchForm from "./modules/Header/MobileSearchForm";
import SingleShop from './modules/SingleShop/SingleShop';
import ProfileRoute from "./Routes/ProfileRoute";
import SystemRoute from "./Routes/SystemRoute";
import Service from './modules/Services/Service';
import Invoice from './modules/Invoice/Invoice';
import PartnerProfile from "./modules/PartnerProfile/Profile";
import SingleFeature from "./modules/PartnerProfile/components/Features/SingleFeature";
import RevUrl from "./modules/SingleShop/components/ShopServices/RevUrl";
import PaySuccess from "./modules/GuestProfile/Appointment/PaySuccess";
import PayFail from "./modules/GuestProfile/Appointment/PayFail";
import VideoCall from "./modules/GuestProfile/Appointment/VideoCall";
import ForGuest from "./modules/ForGuest/ForGuest";
import SubscriptionUrl from "./modules/widgets/subscription/SubscriptionUrl";
import AppStoreLink from "./modules/system/AppStoreLink";

const BasePage = () => {
      return (
            <Router>
                  <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/home" component={Home} />
                        <Route exact path="/mobile-header" component={ResponsiveHeader} />
                        <Route exact path="/search-form" component={MobileSearchForm} />

                        <Route exact path="/for-business" component={PartnerProfile} />
                        <Route exact path="/for-guest" component={ForGuest} />

                        {/* Sign up */}
                        <Route exact path="/signup" component={SignUp} />
                        <Route exact path="/social-registration" component={SocialRegister} />

                        {/* Login */}
                        <Route exact path="/login" component={LoginMail} />
                        <Route exact path="/guest-login" component={GuestLogin} />
                        <Route exact path="/forget-password" component={ForgotPass} />
                        <Route exact path="/reset-password/:token" component={ResetPass} />


                        {/* Search Service */}
                        <Route path="/search/:lat?/:lng?/:service?/:dist?/:sort?/:gender?" exact component={Service} />

                        {/* System pages */}
                        <Route path="/system" component={SystemRoute} />

                        {/* Guest */}
                        <Route path="/profile" component={ProfileRoute} />

                        {/* Invoice */}
                        {/* <Route path="/invoice/:id/:type" component={Invoice} /> */}
                        <Route path="/invoice/:id" component={Invoice} />

                        {/* Revolut URL for mobile app */}
                        <Route exact path="/revolut/:publicId/:amount/:saleId/:revolutId" component={RevUrl} />
                        <Route exact path="/revolut-subscription/:publicId/:amount/:subscribedId/:revolutId" component={SubscriptionUrl} />

                        {/* Partner Feature */}
                        <Route path="/business-feature/:slug" component={SingleFeature} />

                        {/* for payment status */}
                        <Route exact path="/payment-success" component={PaySuccess} />
                        <Route exact path="/payment-fail" component={PayFail} />
                        {/* for app store qr code link*/}
                        <Route exact path="/applink/:type" component={AppStoreLink} />
                        
                        {/* video consultation call */}
                        <Route exact path={`/video-vetting/:room/:userId/:profileName`} component={VideoCall} />

                        <Route exact path={`/:shop_slug`} component={SingleShop} />

                        {/* <Redirect to="/" /> */}
                  </Switch>
            </Router>
      );
}
export default BasePage;