import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Form, Button } from 'react-bootstrap'
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import MetaGenerator from '../widgets/MetaGenerator';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

//API
import { CHANGE_PASSWORD } from "../../../gql/Mutation";

const schema = yup.object().shape({
    oldPass: yup
        .string()
        .required("Please Enter your old password"),
    password: yup
        .string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/])[A-Za-z\d@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and one special case Character"
        ),
    confirm_password: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Confirm Password does not match')
})

export default function ChangePass() {
    const [isPasswordShown1, setIsPasswordShown1] = useState(false);
    const [isPasswordShown2, setIsPasswordShown2] = useState(false);
    let history = useHistory();
    const togglePass1 = () => {
        setIsPasswordShown1(!isPasswordShown1)
    }
    const togglePass2 = () => {
        setIsPasswordShown2(!isPasswordShown2)
    }

    const [updatePassword] = useMutation(CHANGE_PASSWORD);
    const { register, formState: { errors }, handleSubmit, reset } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const onSubmit = data => {
        // setDisplay(false);
        const { oldPass, password, confirm_password } = data;
        updatePassword({
            variables: {
                old_password: oldPass,
                password: password,
                password_confirmation: confirm_password
            }
        }).then(({ data }) => {
            if (data) {
                console.log(data);
                if (data.updatePassword?.status === "PASSWORD_UPDATED") {
                    toast.success(data.updatePassword?.message);
                    history.push("/profile/my-profile");
                    reset();
                } else {
                    toast.error(data.updatePassword?.message);
                }
            }
        }).catch(e => {
            console.log(e);
            toast.error("Internal server error");
        })
    }

    return (
        <>
            <MetaGenerator
                title="Change password"
            />
            <section id="ForgotPass" className="pr-pt-150">
                <div className="mailLoginWrap">
                    <div className="LoginInner">
                        <div className="LoginTop">
                            <span className="title">Change password</span>
                            <Form onSubmit={handleSubmit(onSubmit)} className="loginMailForm" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    handleSubmit(onSubmit)
                                }
                            }}
                                autocomplete="off"
                            >
                                <div className="formInput">
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Old Password*</Form.Label>
                                        <Form.Control type="password" placeholder="Old password" id="oldPass" name="oldPass" {...register('oldPass')}
                                            error={!!errors.oldPass}
                                        />
                                        <span style={{ color: 'red', fontSize: "14px" }}>{errors?.oldPass?.message}</span>
                                        {/* <Form.Control type="email" placeholder="Enter your email address" id="email" name="email" value={password.email} onChange={InputChange} /> */}
                                    </Form.Group>
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Password*</Form.Label>
                                        <div className="passWrap1">
                                            <Form.Control type={isPasswordShown1 ? "text" : "password"} placeholder="Create Password" name="password" {...register('password')}
                                                error={!!errors.password}
                                                autocomplete="off"
                                            />
                                            <i className={`fa ${isPasswordShown1 ? "fa-eye-slash" : "fa-eye"} passwordIcon1`}
                                                onClick={togglePass1}
                                            />
                                        </div>
                                        <span style={{ color: 'red', fontSize: "14px" }}>{errors?.password?.message}</span>
                                    </Form.Group>
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Confirm Password*</Form.Label>
                                        <div className="passWrap2">
                                            <Form.Control type={isPasswordShown2 ? "text" : "password"} placeholder="Confirm Password" name="confirm_password" {...register('confirm_password')}
                                                error={!!errors.confirm_password}
                                                autocomplete="off"
                                            />
                                            <i className={`fa ${isPasswordShown2 ? "fa-eye-slash" : "fa-eye"} passwordIcon2`}
                                                onClick={togglePass2}
                                            />
                                        </div>
                                        <span style={{ color: 'red', fontSize: "14px" }}>{errors?.confirm_password?.message}</span>
                                    </Form.Group>
                                </div>
                                <Button className="signUpBtn resetPassBtn" type="submit">
                                    Change Password
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
