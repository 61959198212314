import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { useQuery } from '@apollo/client';

//API
import { MY_FAVOURITES, PROFILE_INFO } from "../../../gql/Query";
import { ADD_FAVOURITE } from "../../../gql/Mutation";

const AddFavorite = ({ id }) => {
    // if (id) {
    //     console.log("-id-", id)
    // }
    const [addFav, setAddFav] = useState();
    const [status, setStatus] = useState();
    const { data: profileInfo } = useQuery(PROFILE_INFO);

    const history = useHistory();
    const guest_token = localStorage.getItem('guest_token')
    const [save_businesses] = useMutation(ADD_FAVOURITE, {
        refetchQueries: [{
            query: MY_FAVOURITES, variables: { count: 18, page: 1 }
        }],
        awaitRefetchQueries: true,
    });
    useEffect(() => {
        if (profileInfo) {
            // console.log("profileInfo.profileInformation.favorite_business", profileInfo.profileInformation.favorite_business)
            if (profileInfo.profileInformation?.client_detail?.favorite_business?.length > 0) {
                setStatus(profileInfo.profileInformation?.client_detail?.favorite_business?.find((x) =>
                    x.business_id === parseInt(id))
                )
                // console.log("status, id", status, id)
            }
        }
        if (status) {
            setAddFav(1)
        } else {
            setAddFav(0)
        }
    }, [status, profileInfo, id])

    const submitFav = () => {
        if (!guest_token) {
            toast.info("Please login first!");
            history.push("/guest-login")
        } else {
            if (addFav === 0) {
                setAddFav(1)
                save_businesses({
                    variables: {
                        type: 1,
                        business_id: parseInt(id)
                    }
                }).then((data) => {
                    console.log(data);
                    toast.success("Added to your favourite list!");
                }).catch((e) => {
                    console.log(e)
                })
            } else {
                setAddFav(0)
                save_businesses({
                    variables: {
                        type: 0,
                        business_id: parseInt(id)
                    }
                }).then((data) => {
                    console.log(data);
                    toast.success("Removed from your favourite list!");
                }).catch((e) => {
                    console.log(e);
                    toast.error("Internal server error!");
                })
            }
        }
    }
    return (
        <p className="addFavourite" onClick={submitFav}>
            {addFav === 0 ? <i className="far fa-heart" /> : <i className="fas fa-heart" />}
        </p>
    );
}
export default AddFavorite