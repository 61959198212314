import { useState, useEffect } from 'react';
import axios from 'axios';

const GetIP = () => {
    //creating IP state
    const [data, setData] = useState({});
    const getData = async () => {
        try {
            const res = await axios.get('https://geolocation-db.com/json/')
            setData(res.data)
        } catch (e) {
            setData("error")
        }
    }
    useEffect(() => {
        getData()
    }, [])
    console.log("country code", data)
    return data;
}

export default GetIP
