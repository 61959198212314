import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLocation, useHistory } from "react-router-dom";
import BusinessItems from "../../../widgets/BusinessItems";
import { useQuery } from '@apollo/client';
import loader from '../../../../../dist/images/chz-loader.gif';
import MetaGenerator from '../../../widgets/MetaGenerator';

//API
import { SEARCH_SERVICES } from "../../../../../gql/Query"

export default function SearchResult() {
    const history = useHistory();
    const [sortVal, setSortVal] = useState("");
    const [name, setName] = useState("");
    const [genderVal, setGenderVal] = useState("");
    const position = localStorage.getItem("position");
    const service_name = localStorage.getItem("service_name");
    const select_loc = localStorage.getItem("select_loc");
    const [serviceData, setServiceData] = useState({});
    const [count, setCount] = useState(0);
    const [location, setLocation] = useState("");
    const [serviceName, setServiceName] = useState("");
    const searchParam = new URLSearchParams(useLocation().search)
    const lat = searchParam.get("lat");
    const lng = searchParam.get("lng");
    const service = searchParam.get("service");
    const dist = searchParam.get("dist");
    const sort = searchParam.get("sort");
    const gender = searchParam.get("gender");
    useEffect(() => {
        if (sort !== `""`) {
            // console.log("sort", sort)
            setSortVal(sort)
        }
        if (gender !== `""`) {
            // console.log("gender", gender)
            setGenderVal(gender)
        }
        if (service !== `""`) {
            // console.log("service", service)
            setName(service)
        }
    }, [sort, gender, service])
    // if (lat || lng || service || dist) {
    //     console.log("lat, lng, service, dist ", lat, lng, service, dist)
    // }
    const { data, loading, refetch } = useQuery(SEARCH_SERVICES, {
        variables: {
            sort: sortVal,
            gender: genderVal,
            business_type: name,
            lat: lat?.toString(),
            lng: lng?.toString(),
            distance: parseInt(dist)
        }
    })
    // Search services
    useEffect(() => {
        if (data) {
            // console.log("got service data------------", data?.search_business);
            if (data?.search_business) {
                setServiceData(data.search_business);
                // console.log("data?.search_business", data?.search_business)
                setCount(data?.search_business.length);
                window.scrollTo(0, 0);
            }
        }
        if (select_loc) {
            setLocation(`in ${select_loc}`)
        }
        if (service_name) {
            // console.log("service_name", service_name)
            setServiceName(`for ${service_name}`);
        }
        if (service_name === "") {
            setServiceName("");
        }
    }, [position, data, select_loc, service_name]);

    const sortSearch = (val) => {
        setSortVal(val)
        history.push(`/search?lat=${lat}&lng=${lng}&service=${service}&dist=${dist}&sort=${val}&gender=${genderVal}`)
        setTimeout(() => {
            refetch()
        }, 500);
    }
    const genderSearch = (val) => {
        setGenderVal(val)
        history.push(`/search?lat=${lat}&lng=${lng}&service=${service}&dist=${dist}&sort=${sortVal}&gender=${val}`)
        setTimeout(() => {
            refetch()
        }, 500);
    }
    // console.log("serviceName", localStorage.getItem("service_name"))
    // console.log("serviceName", serviceName)

    return (
        <>
            {
                loading &&
                <>
                    <MetaGenerator
                        title="Loading"
                    />
                    <div className='loader_wrap'>
                        <img src={loader} alt="chuzeday" width={180} />
                    </div>
                </>

            }
            {(serviceData) &&
                <>
                    <MetaGenerator
                        // title={(service_name || select_loc) ? ` ${service_name ? service_name:""} ${select_loc}` : "Search results"}
                        title={(service_name || select_loc) ? (service_name ? `${service_name} || ${select_loc}` : select_loc) : "Search results"}
                    />
                    <div id="searchResult">
                        <div className="container-fluid g-profile-container pt-100" >
                            <div className="topheading">
                                {
                                    count === 0 &&
                                    <h2 className="sectionHeading text-capitalize">{`result ${serviceName ? serviceName : ""} ${location ? location : ""}`}</h2>
                                }
                                {count > 1 &&
                                    <h2 className="sectionHeading text-capitalize">{`${count} results found ${serviceName ? serviceName : ""} ${location ? location : ""}`}</h2>
                                }
                                {
                                    count === 1 &&
                                    <h2 className="sectionHeading text-capitalize">{`${count} result found ${serviceName ? serviceName : ""} ${location ? location : ""}`}</h2>
                                }
                                <div className="selectBox">
                                    <div className="selectItem me-2">
                                        <Form.Select aria-label="Default select example" value={sortVal} onChange={(e) => { sortSearch(e.target.value) }}>
                                            {

                                            }
                                            {/* <option>Recommended</option> */}
                                            <option value="">All</option>
                                            <option value="nearest">Nearest</option>
                                            {/* <option value="2">Top rated</option> */}
                                            <option value="newest">Newest</option>
                                            {/* <option value="4">Lowest Price</option> */}
                                        </Form.Select>
                                        <i className="arrow-down fa fa-caret-down"></i>
                                    </div>
                                    <div className="selectItem">
                                        <Form.Select aria-label="Default select example" value={genderVal} onChange={(e) => genderSearch(e.target.value)}>
                                            <option value="">Everyone</option>
                                            <option value="female">Female Only</option>
                                            <option value="male">Male Only</option>
                                        </Form.Select>
                                        <i className="arrow-down fa fa-caret-down"></i>
                                    </div>
                                </div>
                            </div>
                            <div className="resultItem">
                                <div className="container-fluid">
                                    <div className="row venuItem">
                                        {(serviceData?.length > 0) ?
                                            serviceData.map((val) => {
                                                return (
                                                    <BusinessItems content={val} key={val.id} />
                                                )
                                            }) :
                                            <h2 className="text-center">Oops No Results Found!</h2>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
