import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import GetIP from "../../../widgets/GetIP";
import Card from 'react-bootstrap/Card';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from '@apollo/client';
import demoService from '../../../../../dist/images/Services/customer-banner.png';

//API
import { BUSINESS_TYPE } from "../../../../../gql/Query";

const Service = ({ idName }) => {
    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        pauseOnHover: true,
        //cssEase: 'linear',
        dots: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const imageBaseURL = "https://chuzeday.com/";
    const [businessType, setBusinessType] = useState({})
    const { data, error } = useQuery(BUSINESS_TYPE);
    const userLocation = GetIP();
    const history = useHistory();
    const search = (name) => {
        let position = localStorage.getItem("position");
        let select_loc = localStorage.getItem("select_loc");
        let dist = localStorage.getItem("dist");
        if (position && select_loc && dist) {
            let p = JSON.parse(position);
            localStorage.setItem("service_name", name);
            let lat = p[0]?.toString();
            let long = p[1]?.toString();
            history.push(`/search?lat=${lat}&lng=${long}&service=${name}&dist=${dist}&sort=""&gender=""`);
        } else {
            localStorage.setItem("service_name", name);
            localStorage.setItem("dist", "5");
            localStorage.setItem("dist", "5");
            localStorage.setItem("select_loc", `${userLocation?.city}, ${userLocation?.country_name}`);
            history.push(`/search?lat=${userLocation?.latitude}&lng=${userLocation?.longitude}&service=${name}&dist=5&sort=""&gender=""`);
        }
    }
    useEffect(() => {
        if (data) {
            //console.log("data", data)
            setBusinessType(data?.business_types);
        }
        if (error) {
            console.log(error);
        }
    }, [data, error]);
    return (
        <section id={idName}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="service-info">
                            <h2>What Service Are You Looking For?</h2>
                            <button
                                onClick={() => { search("") }}
                                className='btn mt-2 p-3 btn-primary fw-bold mt-5'
                            >
                                See All Businesses Near You
                            </button>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row serviceItem">
                            {businessType?.length > 0 &&
                                <Slider {...settings}>
                                    {
                                        businessType?.map((item) => (
                                            <div key={item?.id} className="col-md-4 service-card-wrapper">
                                                <Card onClick={() => { search(item?.name) }} className="m-0">
                                                    <div className="d-flex justify-content-center">
                                                        <Card.Img variant="top" src={(item?.image ? `${imageBaseURL}${item?.image}` : demoService)} />
                                                    </div>
                                                    <Card.Body>
                                                        <Card.Title className='text-white'>{item?.name}</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Service
