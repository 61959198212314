import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useQuery } from '@apollo/client';
import Avatar from '../../../../../dist/images/guestlogin/blank.png';

//API
import { OPINIONS } from "../../../../../gql/Query";

const Testimonial = () => {
  const imageBaseURL = "https://chuzeday.com/";
  //Slider Settings
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    // autoplaySpeed: 2000,
    pauseOnHover: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const { data } = useQuery(OPINIONS);
  return (
    <section id="Testimonial">
      <div className="venu-heading container-fluid">
        <h2>Testimonials</h2>
      </div>
      <div className="container-fluid testimonialContainer">
        <div className="testimonialItem">
          {
            data &&
            <Slider {...settings}>
              {data?.opinions?.map((val) => {
                return (
                  <div key={val.id}>
                    <div className="testimonialCard">
                      <Card>
                        <img className='card-img-top' src={val.photo ? `${imageBaseURL}${val.photo}` : Avatar} alt={val.name} />
                        <Card.Body>
                          <Card.Title>{val.name}</Card.Title>
                          <span>{val.position}</span>
                          {
                            val?.description?.length > 190 ?
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 500, hide: 750 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {val?.description}
                                  </Tooltip>
                                )}
                              >
                                <Card.Text style={{ cursor: "pointer" }}>
                                  {val?.description ? `${val?.description?.substring(0, 190)}...` : ""}
                                </Card.Text>
                              </OverlayTrigger> :
                              <Card.Text>
                                {val.description}
                              </Card.Text>
                          }
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                )
              })}
            </Slider>
          }
        </div>
      </div>
    </section>
  )
}
export default Testimonial