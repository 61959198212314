import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import Logo from '../../../dist/images/Login/logo.svg';
import arrow from '../../../dist/images/Login/colourLeft.png';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'
import 'dotenv/config'
import 'react-phone-number-input/style.css';
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
// import Reaptcha from 'reaptcha';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import LoginBottom from "./LoginBottom";
import GetIP from "../widgets/GetIP";
import MetaGenerator from '../widgets/MetaGenerator';

//API
import { GUEST_REGISTRATION } from '../../../gql/Mutation';

const schema = yup.object().shape({
    firstName: yup
        .string()
        .required("First name is required"),
    lastName: yup
        .string()
        .required("Last name is required"),
    email: yup
        .string()
        .email("Email should have correct format")
        .required("Email is required"),
    password: yup
        .string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/])[A-Za-z\d@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and one special case Character"
        ),
    confirm_password: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: yup.bool().oneOf([true], 'Accept Terms & privacy policy')
})


export default function SignUp() {
    const [display, setDisplay] = useState(true);
    const [verify, setVerify] = useState(false);
    const [isPasswordShown1, setIsPasswordShown1] = useState(false);
    const [isPasswordShown2, setIsPasswordShown2] = useState(false);
    //phone code from ip address
    const [code, setCode] = useState("IE");
    const userLocation = GetIP();

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
    const guest_token = localStorage.getItem('guest_token')
    const togglePass1 = () => {
        setIsPasswordShown1(!isPasswordShown1)
    }
    const togglePass2 = () => {
        setIsPasswordShown2(!isPasswordShown2)
    }
    const { register, formState: { errors }, handleSubmit, control } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const [phoneNumber, setPhoneNumber] = useState("");
    const [guestRegister] = useMutation(GUEST_REGISTRATION, {
        onError(err) {
            if (err.graphQLErrors[0].extensions.validation.mobile) {
                toast.warn(err.graphQLErrors[0].extensions.validation.mobile[0]);
            }
            else if (err.graphQLErrors[0].extensions.validation.email) {
                toast.warn(err.graphQLErrors[0].extensions.validation.email[0]);
            }
        }
    });
    //----------------captcha functions-----------------
    const verifyCallback = () => {
        setVerify(true)
    }
    const reVerifyCallback = () => {
        setVerify(false)
    }
    const onSubmit = data => {
        // if (verify) {
            setDisplay(false);
            const { firstName, lastName, email, phoneNumber, password } = data;
            guestRegister({
                variables: {
                    first_name: firstName,
                    last_name: lastName,
                    mobile: phoneNumber,
                    email: email,
                    password: password,
                    additional_mobile: "",
                    client_source: "",
                    display_booking: false,
                    gender: "",
                    dob: "",
                    client_info: "",
                    address: "",
                    email_notification: false,
                    marketing_notification: false,
                    language: ""
                }
            }).then(({ data }) => {
                // console.log(data.clientRegister.message);
                setDisplay(true);
                if (data) {
                    if (data.guestRegister.status === 1) {
                        toast.success(data.guestRegister.message);
                        history.push("/login");
                    } else {
                        toast.error(data.guestRegister.message);
                    }
                }
            }).catch(e => {
                setDisplay(true);
                console.log(e);
                toast.error("Internal server error!");
            })

        // }else{
        //     toast.error("Please verify captcha!");
        //     setDisplay(true);
        // }
    }

    useEffect(() => {
        if (userLocation) {
            setCode(userLocation.country_code)
        }

        if (guest_token) {
            toast.info("You are already logged in!");
            history.push("/");
        }
    }, [userLocation, guest_token])

    return (
        <>
            <MetaGenerator
                title="Sign up"
            />
            <section id="SignUp">
                <div className="mailLoginWrap">
                    <div className="LoginInner">
                        <div className="LogoWrap">
                            <span onClick={goToPreviousPath} className="BackHome">
                                <img src={arrow} alt="icon" />
                            </span>
                            <Link to="/home" className="Logo">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <span></span>
                        </div>
                        <div className="LoginTop">
                            <span className="title">Sign Up to Book</span>
                            <Form onSubmit={handleSubmit(onSubmit)} className="signUpForm" autocomplete="off">
                                <div className="formInput">
                                    <Row className="">
                                        <Form.Group as={Col} md={6} className="pe-md-3 mb-3">
                                            <Form.Label>First Name*</Form.Label>
                                            <Form.Control type="text" placeholder="Your First Name" name="firstName" {...register('firstName')}
                                                error={!!errors.firstName}
                                            />
                                            <span className="form-error">{errors?.firstName?.message}</span>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="ps-md-3 mb-3">
                                            <Form.Label>Last Name*</Form.Label>
                                            <Form.Control type="text" placeholder="Your Last Name" name="lastName" {...register('lastName')}
                                                error={!!errors.lastName}
                                            />
                                            <span className="form-error">{errors?.lastName?.message}</span>
                                        </Form.Group>
                                    </Row>
                                    <Row className="">
                                        <Form.Group as={Col} md={6} className="mb-3 pe-sm-3 pe-md-4">
                                            <Form.Label htmlFor="inlineFormInputGroup">
                                                Mobile Number*
                                            </Form.Label>
                                            <InputGroup className="Number d-block">
                                                <PhoneInputWithCountry
                                                    countrySelectProps={{ unicodeFlags: true }}
                                                    defaultCountry={code}
                                                    name="phoneNumber"
                                                    control={control}
                                                    placeholder="Enter phone number"
                                                    international
                                                    withCountryCallingCode
                                                    value={phoneNumber}
                                                    onChange={setPhoneNumber}
                                                // error={!!errors.phoneNumber}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="mb-3 ps-md-3">
                                            <Form.Label>Email address*</Form.Label>
                                            <Form.Control type="email" placeholder="Your Email Address" name="email" {...register('email')}
                                                error={!!errors.email}
                                            />
                                            <span className="form-error">{errors?.email?.message}</span>
                                        </Form.Group>
                                    </Row>
                                    <Row className="">
                                        <Form.Group as={Col} md={6} className="pe-md-3 mb-3">
                                            <Form.Label>Password*</Form.Label>
                                            <div className="passWrap1">
                                                <Form.Control type={isPasswordShown1 ? "text" : "password"} placeholder="Create Password" name="password" {...register('password')}
                                                    error={!!errors.password}
                                                    autocomplete="off"
                                                />
                                                <i className={`fa ${isPasswordShown1 ? "fa-eye-slash" : "fa-eye"} passwordIcon1`}
                                                    onClick={togglePass1}
                                                />
                                            </div>
                                            <span className="form-error">{errors?.password?.message}</span>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="pe-md-3">
                                            <Form.Label>Confirm Password*</Form.Label>
                                            <div className="passWrap2">
                                                <Form.Control type={isPasswordShown2 ? "text" : "password"} placeholder="Confirm Password" name="confirm_password" {...register('confirm_password')}
                                                    error={!!errors.confirm_password}
                                                    autocomplete="off"
                                                />
                                                <i className={`fa ${isPasswordShown2 ? "fa-eye-slash" : "fa-eye"} passwordIcon2`}
                                                    onClick={togglePass2}
                                                />
                                            </div>
                                            <span className="form-error">{errors?.confirm_password?.message}</span>
                                        </Form.Group>
                                    </Row>
                                    <div className="checkCondition">
                                        <Form.Group className="mb-3" id="formGridCheckbox">
                                            <Form.Check type="checkbox" name="acceptTerms" {...register('acceptTerms')}
                                                error={!!errors.acceptTerms}
                                            />
                                        </Form.Group>
                                        <span>I agree to the <Link to="/system/privacy-policy">privacy policy</Link>,<Link to="/system/web-terms"> website terms</Link> and <Link to="/system/booking-terms"> booking terms</Link></span>
                                    </div>
                                    <span className="form-error">{errors?.acceptTerms?.message}</span>
                                    <div className='ps-1'>
                                        {/* <Reaptcha
                                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                                            render="explicit"
                                            onVerify={verifyCallback}
                                            onExpire={reVerifyCallback}
                                        /> */}
                                    </div>
                                </div>
                                {
                                    display &&
                                    <Button className="signUpBtn signUpBtn mx-auto w-50 " type="submit">
                                        Sign Up
                                    </Button>
                                }
                            </Form>
                            <div className="register">
                                <span>Already have a account?</span>
                                <Link to="/login" className="RegisterBtn">
                                    Log In Now
                                </Link>
                            </div>
                        </div>
                        <LoginBottom />
                    </div>
                </div>
            </section>
        </>
    )
}
