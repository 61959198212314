import React, { useState, useEffect } from 'react';
const RatingLabel = ({ val }) => {
    const [label, setLabel] = useState("");
    useEffect(() => {
        //Rating label
        const ratings = [
            {
                val: 1,
                label: "very poor"
            },
            {
                val: 2,
                label: "poor"
            },
            {
                val: 3,
                label: "Average"
            },
            {
                val: 4,
                label: "Good"
            },
            {
                val: 5,
                label: "Excellent"
            }
        ];
        const labelRating = (val) => {
            for (let rating of ratings.reverse()) {
                if (rating.val <= val) {
                    // console.log(rating.label)
                    setLabel(rating.label);
                    break;
                }
            }
            return label
        }
        labelRating(val)
    }, [val, label]);
    return (<>{` ${label}`}</>)
}
export default RatingLabel