import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useQuery } from '@apollo/client';
import BusinessAction from "../../../widgets/CallToAction/BusinessAction";

//API
import { WEBSITE_VISIT_SLIDER } from "../../../../../gql/Query";
import RevolutAffiliateBanner from "../../../Home/revolutAffiliateBanner/RevolutAffiliateBanner";

const AppsImage = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 2000,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        fade: true,
        pauseOnHover: true
    };

    const imageBaseURL = "https://chuzeday.com/";
    const [partnerSlider, setPartnerSlider] = useState({})
    const [guestSlider, setGuestSlider] = useState({})
    const { data: partner, error: partnerError } = useQuery(WEBSITE_VISIT_SLIDER, {
        variables: {
            type: "partner-app-slider"
        }
    })
    const { data: guest, error: guestError } = useQuery(WEBSITE_VISIT_SLIDER, {
        variables: {
            type: "guest-app-slider"
        }
    })
    useEffect(() => {
        if (partner) {
            setPartnerSlider(partner?.website_visit_sliders)
            console.log(partner)
        }
        if (partnerError) {
            console.log(partnerError)
        }
        if (guest) {
            setGuestSlider(guest?.website_visit_sliders)
            console.log(guest)
        }
        if (guestError) {
            console.log(guestError)
        }
    }, [partner, partnerError, guest, guestError]);

    return (partnerSlider?.length > 0 || guestSlider?.length > 0) && (
        <div className="mobile-app pb-5">
            <div className="row mx-0">
                <div className="col-md-6 mobile-app-left">
                    <h2 className="heading text-center">Business App</h2>
                    <Slider {...settings}>
                        {
                            partnerSlider?.length > 0 && partnerSlider?.map((item) => (
                                <img key={item?.id} src={`${imageBaseURL}/${item?.image}`} alt={item?.src} />
                            ))
                        }
                    </Slider>
                </div>
                <div className="col-md-6 h-100 my-auto">
                    <BusinessAction />
                    {/* <RevolutAffiliateBanner layout={'businessProfile'} /> */}
                </div>
            </div>
        </div>
    );
}
export default AppsImage