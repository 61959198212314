import React from 'react';
import Logo from '../../../../dist/images/call-to-action/logo.svg';
import Apk1 from '../../../../dist/images/banner-slider/1.png';
import Apk2 from '../../../../dist/images/banner-slider/2.png';
import { Link } from 'react-router-dom';

const BusinessAction = () => {
    return (
        <div className='call-to-action call-to-action-business'>
            <div className="action-top mx-auto text-center">
                <a href="https://business.chuzeday.com/auth/registration" rel="noreferrer">
                    <img src={Logo} alt="Chuzeday" className='action_logo' />
                    <div className='fw-bolder mt-3'>For Business</div>
                    <div className='bottom-text'>1 Month Free Trial</div>
                    <Link 
                        // href="https://business.chuzeday.com/auth/registration" 
                        to={"/system/guest-query-prompt"}
                        rel="noreferrer" 
                        className='btn btn-lg bg-light mt-2'
                    >
                        Book Demo
                    </Link>
                </a>
            </div>
            <div className="action-bottom text-center mx-auto mt-5">
                <img src={Logo} alt="Chuzeday" className='action_logo' />
                <div className='fw-bolder mt-3'>Download Business App</div>
                <div className='bottom-text'>Manage your Business with Ease</div>
                <div className="d-flex justify-content-center apk mt-2">
                    <a href="https://play.google.com/store/apps/details?id=com.chuzeday.business" target='_blank'>
                        <img src={Apk1} alt="Google play" />
                    </a>
                    <a href="https://apps.apple.com/us/app/chuzeday-for-business/id6443709568" target='_blank'>
                        <img src={Apk2} alt="Apple play" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default BusinessAction