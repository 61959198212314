import React from 'react'
import { Helmet } from "react-helmet";
const MetaGenerator = ({ title, description, imageUrl, keyWords }) => {
    return (
        <>
            <Helmet>
                <title>{title}</title>
                {/* <meta name="description" content={description} />
                <meta name="keywords" content={keyWords} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={imageUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image:alt" content="preveiw image" /> */}
            </Helmet>
        </>
    )
}

export default MetaGenerator
