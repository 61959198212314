import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import MetaGenerator from '../widgets/MetaGenerator';
import loader from '../../../dist/images/chz-loader.gif';
import { toast } from "react-toastify";

//API
import { MY_NOTIFICATION } from "../../../gql/Query";
import { CLEAR_NOTIFICATION } from "../../../gql/Mutation";
import NotificationItem from './NotificationItem';

const Notification = () => {
    const [notificationList, setNotificationList] = useState([]);
    // notification delete
    const [isCheckAll, setIsCheckAll] = useState(false)
    const [isCheck, setIsCheck] = useState([]);

    const { data, loading, error, refetch } = useQuery(MY_NOTIFICATION);
    const [notificationDelete] = useMutation(CLEAR_NOTIFICATION);

    //-----------------clear notification--------------------
    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(notificationList.map((li) => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };
    const clearNotification = () => {
        if (isCheck?.length > 0) {
            notificationDelete({
                variables: {
                    ids: JSON.stringify(isCheck)
                }
            }).then(({ data }) => {
                if (data?.notificationDelete?.status === 1) {
                    toast.success(data?.notificationDelete?.message);
                    refetch();
                    setIsCheck([])
                } else {
                    toast.error(data?.notificationDelete?.message);
                }
            }).catch((e) => {
                console.log(e);
                toast.error("Internal server error!");
            })
        }
    }

    useEffect(() => {
        if (data) {
            setNotificationList(data?.myNotification?.data);
            // data?.myNotification?.data?.map((x) => {
            //     console.log(x)
            // })
        }
        if (error) {
            console.log(error)
        }
    }, [data, error]);
    // console.log("bData.business.id, closeDays, blockedTime, formatServices", bData?.business?.id, closeDays, blockedTime, formatServices)
    return (
        <>
            {
                loading ?
                    <>
                        <MetaGenerator
                            title="Loading"
                        />
                        <div className='loader_wrap'>
                            <img src={loader} alt="chuzeday" width={180} />
                        </div>
                    </> :
                    data &&
                    <>
                        <MetaGenerator
                            title="Notification"
                        />
                        <section className="gray-bg" style={{ height: notificationList?.length < 5 ? "100vh" : "100%", paddingBottom: "10px" }}>
                            <div className="container">
                                <div className="pr-pt-150 mb-5 d-flex justify-content-between">
                                    <h2 className="sectionHeading mb-0">Notification</h2>
                                    {
                                        notificationList?.length > 0 &&
                                        <div>
                                            <input
                                                className='me-2'
                                                type='checkbox'
                                                name="selectAll"
                                                id="selectAll"
                                                onChange={handleSelectAll}
                                                checked={isCheck.length === notificationList?.length ? true : false}
                                            />
                                            {isCheck?.length > 0 &&
                                                <span>
                                                    {`${isCheck.length} items`}
                                                </span>
                                            }
                                            <button onClick={() => { clearNotification() }} className='ms-2 btn btn-danger btn-lg'><i className="fa fa-trash" />
                                            </button>
                                        </div>
                                    }
                                </div>
                                {
                                    notificationList?.length > 0 ?
                                        notificationList?.map((item) => (
                                            <NotificationItem key={item.id} item={item} isCheck={isCheck} setIsCheck={setIsCheck} />
                                        )) :
                                        <div className='resultItem text-center'>
                                            <h2>You don't have any notification!</h2>
                                        </div>
                                }
                            </div>
                        </section>
                    </>
            }
        </>
    )
}

export default Notification