import React from 'react';
import ShopKeeper from '../../../../dist/images/partnerProfile/Feature/shop-keeper.png';

const BusinessOwner = () => {
    return (
        <div className="featuresWrap mt-5">
            <div className="container">
                <div className="feature-bottom">
                    <div className="row feature-bottom-row">
                        <div className="col-md-3">
                            <div className="bg-man">
                                <img src={ShopKeeper} alt={ShopKeeper} />
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="bg-text">
                                <h5>
                                    "I manage my entire business using Chuzeday, and it's really easy to use!
                                    80% of my clients book online with
                                    Chuzeday!"
                                </h5>
                                <h2>
                                    John, Personal Trainer
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessOwner