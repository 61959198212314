import React from 'react';
import ScrollTop from "../widgets/ScrollTop";
const LoginBottom = () => {
    const prt_token = localStorage.getItem("token");
    return (
        <>
            <ScrollTop />
            <div className="LoginBottom">
                <span className="Text1">Join Us for Business</span>
                <span className="Text2">
                    <a href={prt_token ? "https://business.chuzeday.com/home" : "https://business.chuzeday.com/auth/login"} target="_blank" rel="noreferrer" className="BloginBtn me-2">
                        Log In
                    </a>
                    as a professional with Us to manage your business.
                </span>
            </div>
        </>
    )
}

export default LoginBottom
