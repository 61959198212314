import React, { useEffect, useState } from "react";
import MapIcon from '../../../../dist/images/singleShop/about/map.png';
import moment from "moment";
import { Rating } from '@mui/material';
import { Scrollbars } from "react-custom-scrollbars-2";
import { Link } from "react-router-dom";

const ShopAbout = ({ content }) => {
    const about = 200;
    //console.log(content)
    //----------------business schedule calculation----------------------
    const [socialLink, setSocialLink] = useState({})
    console.log("🚀 ~ file: ShopAbout.js:13 ~ ShopAbout ~ socialLink:", socialLink)
    const [today, setToday] = useState([])
    // console.log("today", today)
    const [close, setClose] = useState([{
        day: ""
    }]);
    const [uniq, setUniq] = useState({});
    const res = (val) => Object.values(
        val?.reduce((groups, current) => {
            if (!groups[current.weekday]) {
                groups[current.weekday] = [];
            }
            groups[current.weekday].push(current);
            return groups;
        }, {})
    ).flatMap((value) => {
        if (value.length > 1) {
            return value.map((current, i, arr) => ({
                ...current,
                weekday: i + 1 > 1 ? "" : current.weekday
            }));
        }

        return value;
    });
    useEffect(() => {
        const weekDay = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
        setToday(weekDay[new Date().getDay()])
        if (typeof content?.work_hours === "object") {
            setUniq(res(content?.work_hours))
            const fitlerOnDay = res(content?.work_hours)?.filter((day) => { return day.weekday !== "" });
            const mapOnDays = fitlerOnDay?.map((day) => { return day.weekday })
            let filteredOffDay = weekDay.filter(day => !mapOnDays?.includes(day))
            setClose(filteredOffDay)
            // console.log("filteredOffDay", filteredOffDay)
        }
        if (content?.social_links) {
            setSocialLink(JSON.parse(content.social_links))
            //console.log(JSON.parse(content.social_links))
        }
    }, [content]);

    return (
        <>
            {
                content &&
                <div id="shopAbout">
                    <div className="shopAboutInner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-12" >
                                    <div className="aboutLeft">
                                        <h2 className="sectionHeading">About <span className="shop-name ps-0">{content?.name}</span></h2>
                                        {
                                            content?.about?.length > about ?
                                                <Scrollbars style={{ height: "180px", width: "90%" }}>
                                                    <p>{content?.about}</p>
                                                </Scrollbars> :
                                                <p style={{ width: "90%" }}>{content?.about}</p>
                                        }
                                        {content.website &&
                                            <p className={content?.about?.length > about && "pt-3"}>
                                                Visit our site:

                                                <Link to={{ pathname: content.website.indexOf("https://") < 0 ? `https://${content.website}` : content.website }} target="_blank">{content.name} </Link>
                                            </p>
                                        }
                                        <div className="ratingWrap">
                                            <div className="rating">
                                                {
                                                    content?.rating > 0 &&
                                                    <div className="d-flex">
                                                        <Rating
                                                            value={content?.rating}
                                                            name="half-rating"
                                                            precision={0.1}
                                                            readOnly
                                                        />
                                                        <p>{content?.rating}</p>
                                                        <div className="ratingCount ms-5">
                                                            <span className="ratingCounter">
                                                                {content?.number_of_ratings}
                                                            </span>
                                                            <span className="ratingText">
                                                                {content?.number_of_ratings > 1 ? "Ratings" : "Rating"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <h2 className="sectionHeading">Business Hour</h2>
                                    {
                                        uniq?.length > 0 &&
                                        uniq?.map((item) => (
                                            <div className="row mb-1" key={item.id}>
                                                <div className="col-4 timeWrap">
                                                    {item.weekday !== "" &&
                                                        <>
                                                            <i className={today === item.weekday ? "far fa-clock text-success clock" : "far fa-clock clock"} />
                                                            <span className={today === item.weekday ? 'text-capitalize p-2 text-success' : "text-capitalize p-2"}>{`${item.weekday}: `}</span>
                                                        </>
                                                    }
                                                </div>
                                                <div className="col-6 timeWrap">
                                                    <span className={today === item.weekday && "text-success"}>{`${moment.unix(item.s_time).utcOffset('+0000').format('h:mm a')} to ${moment.unix(item.e_time).utcOffset('+0000').format('h:mm a')}`}</span>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {/* ------------------close day---------------------- */}
                                    {
                                        close?.length > 0 &&
                                        close?.map((item) => (
                                            <div className="row mb-2" key={item}>
                                                {
                                                    item &&
                                                    <>
                                                        <div className="col-4 timeWrap">
                                                            <i className={today === item ? "far fa-clock clock text-warning" : "far fa-clock clock"} />
                                                            <span className={today === item ? 'text-capitalize text-warning p-2 fw-bold' : "text-capitalize p-2"}>{`${item}: `}</span>
                                                        </div>
                                                        <div className="col-6 timeWrap">
                                                            <span className={today === item && "text-warning fw-bold"}>Closed</span>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                                <div className="col-lg-2 venuItem col-12">
                                    <div className="aboutRight">
                                        <h2 className="sectionHeading">Location</h2>
                                        <div className="location">
                                            <span>
                                                {content.location}
                                            </span>
                                            <p><img src={MapIcon} alt="icon" /></p>
                                        </div>
                                        <a className="btn venuBtn btn-lg my-4" href={`https://www.google.com/maps?daddr=${encodeURI(content.location)}`} target="_blank" rel="noreferrer">See Directions</a>
                                        {socialLink &&
                                            <div className="d-flex social-icon">
                                                {socialLink.facebook &&
                                                    <Link to={{ pathname: socialLink.facebook.indexOf("https://") < 0 ? `https://${socialLink.facebook}` : socialLink.facebook }} className="btn business-social-fb me-2" target="_blank"><i className="fab fa-facebook-square" /> </Link>
                                                }
                                                {socialLink.instagram &&
                                                        <Link to={{ pathname: socialLink.instagram.indexOf("https://") < 0 ? `https://${socialLink.instagram}` : socialLink.instagram }} className="btn business-social-insta me-2" target="_blank"><i className="fa fa-instagram " /> </Link>
                                                }
                                                {socialLink.linkedin &&
                                                        <Link to={{ pathname: socialLink.linkedin.indexOf("https://") < 0 ? `https://${socialLink.linkedin}` : socialLink.linkedin }} className="btn business-social-linkedin me-2" target="_blank"><i className="fa fa-linkedin " /> </Link>
                                                }
                                                {socialLink.tiktok &&
                                                        <Link to={{ pathname: socialLink.tiktok.indexOf("https://") < 0 ? `https://${socialLink.tiktok}` : socialLink.tiktok }} className="btn business-social-tiktok  me-2" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/></svg> </Link>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default ShopAbout