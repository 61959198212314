import React, { useState, useEffect } from 'react';
import { Tab, Row, Col, Modal } from 'react-bootstrap';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import loader from '../../../../dist/images/chz-loader.gif';
import Logo from '../../../../dist/images/logo.svg';
import { Rating } from '@mui/material';
import { toast } from 'react-toastify';
import DateTimeModal from "../../SingleShop/components/ShopServices/DateTimeModal";
import AptLeft from "./AptLeft";
import AptRight from "./AptRight";
import MetaGenerator from '../../widgets/MetaGenerator';
import { useLocation } from 'react-router-dom';

//API
import { MY_APPOINTMENTS, TIME_SLOT } from "../../../../gql/Query";
import { CHANGE_APPOINTMENT, ADD_REVIEW } from "../../../../gql/Mutation";

export default function Appointments() {
    const [profileName, setProfileName] = useState("");
    const [blockedTime, setBlockedTime] = useState({});
    const [status, setStatus] = useState("upcoming");
    const [appid, setAppid] = useState();
    const [bID, setbID] = useState();
    const [showRating, setShowRating] = useState(false);
    const [room, setRoom] = useState("");
    const [showCancel, setShowCancel] = useState(false);
    const [showReschedule, setShowReschedule] = useState(false);
    const [pages, setPages] = useState(50);
    const [pages2, setPages2] = useState(50);
    const [upAppoint, setUpAppoint] = useState({});
    const [pastAppoint, setPastAppoint] = useState({});
    const [upPaginate, setUpPaginate] = useState();
    const [pastPaginate, setPastPaginate] = useState();
    const [toggleState, setToggleState] = useState();
    const [comment, setComment] = useState("");
    const [value, setValue] = useState();
    const [formatServ, setFormatServ] = useState([]);
    const [add_review] = useMutation(ADD_REVIEW);
    const [date, setDate] = useState("");

    //------------------get appointment ID from notification----------------
    const location = useLocation();

    //--------------------close days----------------------
    const [closeDays, setCloseDays] = useState({})
    const { data: upData, loading: upLoading, refetch: upRefetch } =
        useQuery(MY_APPOINTMENTS, {
            variables: {
                date_range: "",
                keyword: "",
                type: "upcoming",
                count: pages,
                page: 1
            }
        });
    const { data: pastData, loading: pastLoading, refetch: pastRefetch } =
        useQuery(MY_APPOINTMENTS, {
            variables: {
                date_range: "",
                keyword: "",
                type: "past",
                count: pages2,
                page: 1
            }
        });

    //------------------closedays--------------------

    const weekDay = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
    const closeProcess = (data) => {
        var mapOnDays = []
        // console.log("data", data)
        data?.map((x) => mapOnDays.push(x.weekday))
        let filteredOffDay = weekDay.filter(day => !mapOnDays?.includes(day));
        const formatDay = []
        filteredOffDay?.map((x) => formatDay.push({ __typename: "BusinessWeekday", weekday: x }));
        // console.log("formatDay", formatDay);
        setCloseDays(formatDay);
    }

    //-------------update timeslot for reschedule---------
    const [slotRefetch, { error: slotError }] = useLazyQuery(TIME_SLOT, {
        variables: {
            business_id: parseInt(bID),
            date: date
        }
    });

    useEffect(() => {
        if (upData) {
            setUpAppoint(upData.appointments?.data);
            setUpPaginate(upData.appointments?.paginatorInfo?.total);
            setProfileName(`${upData.me?.first_name} ${upData.me?.last_name}`);
            // console.log("upData.appointments?.data[0].id", upData.appointments?.data[0]?.id);
        }
        if (pastData) {
            setPastAppoint(pastData.appointments?.data)
            setPastPaginate(pastData.appointments?.paginatorInfo?.total)
        }
        if (upData?.appointments?.data[0]?.id && status === "upcoming") {
            setToggleState(upData.appointments?.data[0]?.id);
            //console.log("upData.appointments?.data[0]?.id", upData.appointments?.data[0]?.id);
        } else {
            setToggleState(pastData?.appointments?.data[0]?.id);
        }
        if (slotError) {
            console.log("slotError", slotError);
        }
        if (location.state) {
            const { aptId, sts } = location.state;
            if (aptId) {
                upRefetch();
                pastRefetch();
                setStatus(sts);
                if (aptId === "all") {
                    setToggleState(pastData?.appointments?.data[0]?.id);
                } else {
                    setToggleState(aptId.toString());
                }
            }
        }
    }, [upData, pastData, status, slotError])

    // if (upAppoint && pastAppoint) {
    //     console.log("upAppoint pastAppoint", upAppoint, pastAppoint)
    // }
    // if (upAppoint.length > 0) {
    //     upAppoint?.map((item) => {
    //         console.log("item", item.business_info.name)
    //         item.appointment_detail.map((x) => {
    //             console.log(x)
    //         })
    //     })
    // }

    const formatServices = () => {
        const servicePriceIDList = [];
        const voucherPriceIDList = [];
        if (formatServ) {
            formatServ.map((x) => {
                if (x.service_pricing?.__typename === "ServicePricing") {
                    servicePriceIDList.push(x.service_pricing?.id)
                } else {
                    voucherPriceIDList.push({})
                }
            })
        }
        const totalIDList = {
            service: servicePriceIDList,
            voucher: voucherPriceIDList
        }
        return JSON.stringify(totalIDList);
    }

    //-----------custom tab left to right click-------------------
    const toggleTab = (index) => {
        window.scrollTo(0, 0);
        setToggleState(index);
    };
    //--------------Modal-----------------
    const ratingModal = (id) => {
        setAppid(id);
        setShowRating(true);
    }
    const cancelModal = (id, room) => {
        if (room) {
            setRoom(room)
        }
        setAppid(id);
        setShowCancel(true);
    }
    const reschedulelModal = (item) => {
        setFormatServ(item.appointment_detail);
        setAppid(item.id);
        setbID(item.business_id);
        closeProcess(item?.business_info?.weekdays);
        setShowReschedule(true);
        setBlockedTime(item?.business_info?.block_dates);
        console.log("item?.business_info", item?.business_info)
    }
    const [statusUpdateForAppointment] = useMutation(CHANGE_APPOINTMENT);

    //--------Rating---------------------------
    const rateNow = () => {
        const token = localStorage.getItem("guest_token");
        if (token) {
            if (value) {
                add_review({
                    variables: {
                        appt_id: parseInt(appid),
                        rating: value,
                        comment: comment
                    }
                }).then(({ data }) => {
                    if (data?.add_review?.status === 1) {
                        toast.success("Thank you!");
                        setComment("");
                        setValue();
                        pastRefetch();
                    } else {
                        toast.error(data?.add_review?.message);
                    }
                    setShowRating(false)
                }).catch((e) => {
                    console.log(e);
                    toast.error("Internal server error!");
                })
            } else {
                toast.error("Please give rating first!");
            }
        } else {
            window.location.replace("/")
        }
    }

    //---------cancel-----------
    const cancel = () => {
        const token = localStorage.getItem("guest_token");
        if (token) {
            statusUpdateForAppointment({
                variables: {
                    id: parseInt(appid),
                    status: "Cancelled",
                    date: "",
                    time: "",
                    note: ""
                }
            }).then(({ data }) => {
                if (data?.statusUpdateForAppointment?.status === 1) {
                    toast.success(data?.statusUpdateForAppointment?.message);
                    upRefetch();
                    pastRefetch();
                } else {
                    toast.error(data?.statusUpdateForAppointment?.message);
                }
                setShowCancel(false)
            }).catch((e) => {
                console.log(e);
                toast.error("Internal server error!");
            })
        } else {
            window.location.replace("/")
        }
    }


    //----------------switch tab-----------------
    const switchTab = (s, id) => {
        location.state = null;
        console.log("s, id", s, id);
        setStatus(s);
        setToggleState(id);
    }

    //---------Reschedule-----------
    const reschedule = (time, date) => {
        setDate(date);
        const token = localStorage.getItem("guest_token");
        if (token) {
            statusUpdateForAppointment({
                variables: {
                    id: parseInt(appid),
                    status: "Reschedule",
                    date: date,
                    time: time,
                    note: ""
                }
            }).then(({ data }) => {
                if (data?.statusUpdateForAppointment?.status === 1) {
                    toast.success(data?.statusUpdateForAppointment?.message);
                    upRefetch();
                    setFormatServ([]);
                    slotRefetch();
                } else {
                    toast.error(data?.statusUpdateForAppointment?.message);
                    setFormatServ([]);
                }
                setShowReschedule(false)
            }).catch((e) => {
                console.log(e);
                toast.error("Internal server error!");
            })
        } else {
            window.location.replace("/");
        }
    }
    //console.log("toggleState", toggleState)
    return (
        <>
            <MetaGenerator
                title="My appointments"
            />
            <section id="appointments" className="gray-bg">
                <div className="container appn-container pr-pt-150 pb-60" style={{ height: upPaginate === 0 && pastPaginate === 0 ? "100vh" : "max-content" }}>
                    {
                        (upPaginate === 0 && pastPaginate === 0) ?
                            <div className='resultItem text-center'>
                                <h2>You haven't booked any appointment yet!</h2>
                            </div> :
                            <Tab.Container>
                                <Row>
                                    <Col sm={4}>
                                        {
                                            (!upLoading && !pastLoading) &&
                                            <div className="venuItem mb-5">
                                                <button className={status === "upcoming" ? "venuBtn btn me-2" : "inactive-venu-btn btn me-2"} onClick={() => { switchTab('upcoming', upAppoint[0]?.id) }}>Upcoming</button>
                                                <button className={status === "past" ? "venuBtn btn ms-2" : "inactive-venu-btn btn ms-2"} onClick={() => { switchTab('past', pastAppoint[0]?.id) }}>Past</button>
                                            </div>
                                        }
                                        <div className="flex-column" >
                                            {
                                                upPaginate > 0 && status === "upcoming" &&
                                                <>
                                                    <div className="appn-tab-heading d-flex align-items-center">
                                                        <h3>Upcoming appointments</h3>
                                                        <span className='bg-white rounded-3 py-2 px-3'>{upPaginate}</span>
                                                    </div>
                                                    {
                                                        upAppoint?.map((item, i) => (
                                                            <AptLeft key={i} item={item} toggleTab={toggleTab} toggleState={toggleState} />
                                                        ))
                                                    }
                                                    {
                                                        upPaginate > 5 && pages < upPaginate &&
                                                        <div className="venuItem d-flex justify-content-end">
                                                            <button className="venuBtn btn btn-sm" onClick={() => { setPages(pages + 10); }} title="Load More"><i className="fas fa-angle-double-right" /></button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            {
                                                pastPaginate > 0 && status === "past" &&
                                                <>
                                                    <div className="appn-tab-heading d-flex align-items-center">
                                                        <h3>Past appointments</h3>
                                                        <span className='bg-white rounded-3 py-2 px-3'>{pastPaginate}</span>
                                                    </div>
                                                    {
                                                        pastAppoint?.map((item, i) => (
                                                            <AptLeft key={i} item={item} toggleTab={toggleTab} toggleState={toggleState} />
                                                        ))
                                                    }
                                                    {
                                                        pastPaginate > 5 && pages2 < pastPaginate &&
                                                        <div className="venuItem d-flex justify-content-end">
                                                            <button className="venuBtn btn btn-sm" onClick={() => { setPages2(pages2 + 10); }} title="Load More"><i className="fas fa-angle-double-right" /></button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </Col>
                                    <Col sm={8} className="apt-right">
                                        {(upLoading || pastLoading) &&
                                            <div className='loader_wrap'>
                                                <img src={loader} alt="chuzeday" width={180} />
                                            </div>
                                        }
                                        <>
                                            {
                                                (status === "upcoming" && upAppoint?.length) > 0 ?
                                                    upAppoint?.map((item, i) => (
                                                        <AptRight
                                                            key={i}
                                                            item={item}
                                                            toggleState={toggleState}
                                                            reschedulelModal={reschedulelModal}
                                                            cancelModal={cancelModal}
                                                            status="upcoming"
                                                            profileName={profileName}
                                                        />
                                                    )) : status === "upcoming" &&
                                                    <div className='resultItem text-center' style={{ height: "80vh" }} >
                                                        <h2>You don't have any upcoming appointment!</h2>
                                                    </div>
                                            }
                                            {
                                                (status === "past" && pastAppoint?.length > 0) ?
                                                    pastAppoint?.map((item, i) => (
                                                        <AptRight
                                                            key={i}
                                                            item={item}
                                                            toggleState={toggleState}
                                                            ratingModal={ratingModal}
                                                            status="past"
                                                        />
                                                    )) : status === "past" &&
                                                    <div className='resultItem text-center' style={{ height: "80vh" }}>
                                                        <h2>You don't have any past appointment!</h2>
                                                    </div>
                                            }
                                        </>
                                    </Col>
                                </Row>
                            </Tab.Container>
                    }
                </div>

                {/* -----------------------Rating Modal-------------------- */}
                <Modal
                    show={showRating}
                    onHide={() => { setShowRating(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="chuez-modal"
                >
                    <Modal.Header closeButton>
                        <h3 className='input-section-heading'>
                            Please give us your valuable feedback!
                        </h3>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-flex justify-content-center mb-3 flex-column align-items-center'>
                            <div className="logo">
                                <img src={Logo} alt="Logo-img" />
                            </div>
                            <Rating
                                value={value}
                                name="half-rating"
                                onChange={(event, newValue) => {
                                    setValue(newValue);
                                }}
                            />
                            <input type="text" className="form-control mb-3" value={comment} onChange={(e) => { setComment(e.target.value) }} />
                            <button className='btn primaryBtn p-3' onClick={() => { rateNow() }}>Submit</button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* -------------------------cancel modal-------------------- */}
                <Modal
                    show={showCancel}
                    onHide={() => { setShowCancel(false) }}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="chuez-modal status-changed-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title />
                    </Modal.Header>
                    <Modal.Body className='text-center venuItem'>
                        <h3>
                            {
                                room !== "" ?
                                    (room.split("-")[3] ?
                                        "Are you sure?" :
                                        "If you cancel consultation video call, associated appointment of services will be cancelled too. Do you want to cancel?"
                                    ) : "Are you sure?"
                            }
                        </h3>
                        <div className="d-flex justify-content-center mt-5">
                            <button onClick={() => { cancel() }} className='venuBtn btn btn-sm me-2'>Yes</button>
                            <button onClick={() => { setShowCancel(false) }} className='venuBtn btn btn-sm ms-2'>No</button>
                        </div>
                    </Modal.Body>
                </Modal>
                {/* -------------------------Reschedule modal-------------------- */}
                <Modal
                    show={showReschedule}
                    onHide={() => { setShowReschedule(false) }}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="chuez-modal status-changed-modal"
                >
                    <DateTimeModal
                        bID={bID}
                        checkAuth={reschedule}
                        closeDays={closeDays}
                        blockedTime={blockedTime}
                        formatServices={formatServices}
                        vetting={false}
                        notification={false}
                    />
                </Modal>
            </section>
        </>
    )
}
