import React from 'react';
import Service from '../Home/components/Service/Service';
import Feature from '../Home/components/Feature/Feature';
import Footer from '../Footer/Footer';
import MetaGenerator from '../widgets/MetaGenerator';
import ScrollTop from '../widgets/ScrollTop';
import SeconderyHeader from '../Header/SeconderyHeader';
import AppsImage from './AppsImage';

const ForGuest = () => {
    return (
        <>
            <MetaGenerator
                title="For Guest"
            />
            <ScrollTop />
            <div id="filterHeader">
                <SeconderyHeader />
            </div>
            <div className="for_guest_bg">
                <div className='my-5 px-5 for_guest_bar'>
                    <Service idName="forGuest" />
                </div>
                <AppsImage />
                <div className='my-5'>
                    <Feature />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ForGuest;