import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import Logo from '../../../dist/images/Login/logo.svg';
import arrow from '../../../dist/images/Login/colourLeft.png';
import backarrow from '../../../dist/images/Login/backLogin.png';
import { Form, Button } from 'react-bootstrap'
import LoginBottom from "./LoginBottom";
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import MetaGenerator from '../widgets/MetaGenerator';

//API
import { FORGET_PASSWORD } from "../../../gql/Mutation"

export default function ForgotPass() {
    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }
    const guest_token = localStorage.getItem('guest_token')
    if (guest_token) {
        history.push("/")
    }
    const [email, setEmail] = useState("");
    const [forgotPassword] = useMutation(FORGET_PASSWORD);
    const resetPassword = (e) => {
        e.preventDefault();
        if (!email) {
            toast.error("Email field can't be empty");
            document.querySelector(`#email`).focus();
            document.querySelector(`#email`).scrollIntoView({ behavior: 'smooth', block: 'end' });
        } else {
            forgotPassword({
                variables: {
                    email: email
                }
            }).then(({ data }) => {
                if (data.forgotPassword.status === 0) {
                    toast.error(data.forgotPassword.message);
                } else {
                    toast.success(data.forgotPassword.message);
                }
            }).catch(error => {
                toast.error(error.message);
            })
        }
    }
    useEffect(() => {
        document.querySelector(`#email`).focus();
    }, [])
    return (
        <>
            <MetaGenerator
                title="Forgot password"
            />
            <section id="ForgotPass">
                <div className="mailLoginWrap">
                    <div className="LoginInner">
                        <div className="LogoWrap">
                            <span onClick={goToPreviousPath} className="BackHome">
                                <img src={arrow} alt="icon" />
                            </span>
                            <Link to="/home" className="Logo">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <span></span>
                        </div>
                        <div className="LoginTop">
                            <span className="title">Forgot password?</span>
                            <Form className="loginMailForm" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    resetPassword(e);
                                }
                            }}
                                autocomplete="off"
                            >
                                <div className="formInput">
                                    <span>Enter your registered email address and we'll send you a secure link to setup a new password.</span>
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" id="email" placeholder="Enter your email address" onChange={(e) => setEmail(e.target.value)} />
                                    </Form.Group>
                                </div>
                                <Button className="signUpBtn resetPassBtn" type="submit" onClick={resetPassword}>
                                    Reset Password
                                </Button>
                            </Form>
                            <div className="register">
                                <Link to="/login" className="RegisterBtn backLogin">
                                    <img src={backarrow} alt="icon" />
                                    Back to login
                                </Link>
                            </div>
                        </div>
                        <LoginBottom />
                    </div>
                </div>
            </section>
        </>
    )
}
