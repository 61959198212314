import React from 'react';
import RevolutPayment from "./RevolutPayment";
import { useParams } from 'react-router-dom';
import MetaGenerator from '../../../widgets/MetaGenerator';

const RevUrl = () => {
    let { publicId, amount, saleId, revolutId } = useParams();
    //console.log("publicId, amount, saleId, revolutId", publicId, amount, saleId, revolutId)
    amount = parseFloat(amount);
    saleId = parseInt(saleId);

    return (
        <div className="container my-4">
            <MetaGenerator title="Revolut payment" />
            <RevolutPayment
                order={publicId}
                amount={amount}
                saleId={saleId}
                revolutId={revolutId}
            />
        </div>
    )
}

export default RevUrl