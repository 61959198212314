import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams } from "react-router-dom";
import Logo from '../../../dist/images/Login/logo.svg';
import arrow from '../../../dist/images/Login/colourLeft.png';
import backarrow from '../../../dist/images/Login/backLogin.png';
import { Form, Button } from 'react-bootstrap'
import LoginBottom from "./LoginBottom";
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import MetaGenerator from '../widgets/MetaGenerator';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

//API
import { RESET_PASSWORD } from "../../../gql/Mutation";

const schema = yup.object().shape({
    email: yup
        .string()
        .email("Email should have correct format")
        .required("Email is required"),
    password: yup
        .string()
        .required('Please Enter your password')
        .matches(
            /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/])[A-Za-z\d@$!%*#?&~`^()_\-+={[}\]|:;"'<,.>?\\/]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and one special case Character"
        ),
    confirm_password: yup
        .string()
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Confirm Password does not match'),
    acceptTerms: yup.bool().oneOf([true], 'Accept Terms & privacy policy')
})

export default function ResetPass() {
    const [isPasswordShown1, setIsPasswordShown1] = useState(false);
    const [isPasswordShown2, setIsPasswordShown2] = useState(false);
    const { token } = useParams();
    let history = useHistory();
    const togglePass1 = () => {
        setIsPasswordShown1(!isPasswordShown1)
    }
    const togglePass2 = () => {
        setIsPasswordShown2(!isPasswordShown2)
    }
    const goToPreviousPath = () => {
        history.goBack()
    }
    // console.log(password)
    const [resetPassword] = useMutation(RESET_PASSWORD);
    const { register, formState: { errors }, handleSubmit } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });
    const onSubmit = data => {
        // setDisplay(false);
        const { email, password, confirm_password } = data;
        resetPassword({
            variables: {
                token: token,
                email: email,
                password: password,
                password_confirmation: confirm_password
            }
        }).then(({ data }) => {
            // console.log(data.clientRegister.message);
            if (data?.resetPassword) {
                if (data.resetPassword.status === 1) {
                    toast.success(data.resetPassword.message);
                    history.push("/login");
                } else {
                    toast.error(data.resetPassword.message);
                }
            }
        }).catch(e => {
            console.log(e);
            toast.error(e.message);
        })
    }

    useEffect(() => {
        document.querySelector(`#email`).focus();
    }, [])
    return (
        <>
            <MetaGenerator
                title="Reset password"
            />
            <section id="ForgotPass">
                <div className="mailLoginWrap">
                    <div className="LoginInner">
                        <div className="LogoWrap">
                            <span onClick={goToPreviousPath} className="BackHome">
                                <img src={arrow} alt="icon" />
                            </span>
                            <Link to="/home" className="Logo">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <span></span>
                        </div>
                        <div className="LoginTop">
                            <span className="title">Forgot password?</span>
                            <Form onSubmit={handleSubmit(onSubmit)} className="loginMailForm" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    handleSubmit(onSubmit)
                                }
                            }}
                                autocomplete="off"
                            >
                                <div className="formInput">
                                    <span>Enter your registered email address and we'll send you a secure link to setup a new password.</span>
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Email address*</Form.Label>
                                        <Form.Control type="email" placeholder="Email address" id="email" name="email" {...register('email')}
                                            error={!!errors.email}
                                        />
                                        <span style={{ color: 'red', fontSize: "14px" }}>{errors?.email?.message}</span>
                                        {/* <Form.Control type="email" placeholder="Enter your email address" id="email" name="email" value={password.email} onChange={InputChange} /> */}
                                    </Form.Group>
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Password*</Form.Label>
                                        <div className="passWrap1">
                                            <Form.Control type={isPasswordShown1 ? "text" : "password"} placeholder="Create Password" name="password" {...register('password')}
                                                error={!!errors.password}
                                            />
                                            <i className={`fa ${isPasswordShown1 ? "fa-eye-slash" : "fa-eye"} passwordIcon1`}
                                                onClick={togglePass1}
                                            />
                                        </div>
                                        <span style={{ color: 'red', fontSize: "14px" }}>{errors?.password?.message}</span>
                                    </Form.Group>
                                    <Form.Group className="mb-md-3 mb-2">
                                        <Form.Label>Confirm Password*</Form.Label>
                                        <div className="passWrap2">
                                            <Form.Control type={isPasswordShown2 ? "text" : "password"} placeholder="Confirm Password" name="confirm_password" {...register('confirm_password')}
                                                error={!!errors.confirm_password}
                                            />
                                            <i className={`fa ${isPasswordShown2 ? "fa-eye-slash" : "fa-eye"} passwordIcon2`}
                                                onClick={togglePass2}
                                            />
                                        </div>
                                        <span style={{ color: 'red', fontSize: "14px" }}>{errors?.confirm_password?.message}</span>
                                    </Form.Group>
                                </div>
                                <Button className="signUpBtn resetPassBtn" type="submit">
                                    Reset Password
                                </Button>
                            </Form>
                            <div className="register">
                                <Link to="/login" className="RegisterBtn backLogin">
                                    <img src={backarrow} alt="icon" />
                                    Back to login
                                </Link>
                            </div>
                        </div>
                        <LoginBottom />
                    </div>
                </div>
            </section>
        </>
    )
}
