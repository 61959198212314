import React, { useState } from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const GeoSuggest = ({ onSelectLocation }) => {
    const [address, setAddress] = useState("");
    // const select_loc = localStorage.getItem("select_loc")
    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setAddress(value);
        onSelectLocation(value, latLng?.lat, latLng?.lng, "101");
        localStorage.setItem("loc", "101");
        localStorage.setItem("select_loc", value);
    };
    //Tooltip
    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props} className="h1">
            <span>Find Near Me</span>
        </Tooltip>
    );
    //Find Near Me
    const nearMe = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            // console.log("position", position)
            onSelectLocation("", position?.coords?.latitude, position?.coords?.longitude, "102");
            localStorage.setItem("loc", "102");
            // if (select_loc) {
            //     localStorage.removeItem("select_loc");
            // }
        });
    }

    return (

        <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div className="location-popup">
                    {/* <p>Latitude: {coordinates.lat}</p>
                        <p>Longitude: {coordinates.lng}</p> */}
                    <div className="input-group">
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltip}
                        >
                            <div className="input-group-prepend" onClick={nearMe}>
                                <span className="input-group-text"><i className="far fa-location-arrow" /></span>
                            </div>
                        </OverlayTrigger>

                        <input type="text" className="form-control location-input" {...getInputProps({ placeholder: "Enter Location" })} />
                    </div>
                    <div>
                        {loading ? <ul><li className="bg-white">Loading...</li></ul> : null}
                        {/* <ul><li>Loading...</li> <li>Loading...</li> <li>Loading...</li> <li>Loading...</li></ul> */}
                        {suggestions?.length > 0 &&
                            <ul>
                                {suggestions.map((suggestion) => {
                                    const style = {
                                        backgroundColor: suggestion.active ? "#37a1d2" : "white",
                                        color: suggestion.active ? "white" : "black",
                                        cursor: "pointer"
                                    };
                                    return (
                                        <li key={suggestion.placeId} className="recent-search-location d-flex align-items-center mb-2" {...getSuggestionItemProps(suggestion, { style })}>
                                            {suggestion.description}
                                        </li>
                                    );
                                })}
                            </ul>}

                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
}
export default GeoSuggest