import React, { useEffect } from 'react';
import { Route, useHistory } from "react-router-dom";
import MyProfile from "../modules/GuestProfile/MyProfile";
import Appointments from "../modules/GuestProfile/Appointment/Appointments";
import Notification from "../modules/GuestProfile/Notification";
import Favorites from "../modules/GuestProfile/Favorites";
import Vouchers from "../modules/GuestProfile/Vouchers";
import FixHeader from '../modules/Header/FixHeader';
import ChangePass from '../modules/GuestProfile/ChangePass';
import DeleteAccount from '../modules/GuestProfile/DeleteAccount';
import { useApolloClient, useQuery } from '@apollo/client';

//API
import { AUTH_CHECK } from '../../gql/Query';
import { toast } from 'react-toastify';
import Loader from '../modules/system/Loader';

const ProfileRoute = ({ match }) => {
    const { data, loading, error } = useQuery(AUTH_CHECK);
    // if (match) {
    //     console.log("match", match)
    // }
    const history = useHistory();
    const client = useApolloClient();
    useEffect(() => {
        const guest_token = localStorage.getItem('guest_token');
        if (!guest_token) {
            history.push("/")
        }
        if (error) {
            if (error.graphQLErrors[0]?.message === "Unauthenticated.") {
                localStorage.clear();
                client.cache.reset();
                setTimeout(() => {
                    history.push("/login")
                }, 100)
                toast.error("Please login first!")
            } else {
                history.push("/home")
                toast.error("Internal server error!")
            }
        }
    }, [history, error])

    return data ? (
        <>
            <FixHeader />
            <Route exact path={match.url} component={MyProfile} />
            <Route exact path={`${match.url}/my-profile`} component={MyProfile} />
            <Route exact path={`${match.url}/appointments`} component={Appointments} />
            <Route exact path={`${match.url}/favorites`} component={Favorites} />
            <Route exact path={`${match.url}/vouchers`} component={Vouchers} />
            <Route exact path={`${match.url}/change-password`} component={ChangePass} />
            <Route exact path={`${match.url}/delete-account`} component={DeleteAccount} />

            {/* Notification */}
            <Route exact path={`${match.url}/notification`} component={Notification} />
        </>
    ) : (
        loading ?
            <div className='my-5'> <Loader /> </div> :
            <div className='my-5'> Something went wrong! </div>
    )
}

export default ProfileRoute
