import React, { useState } from 'react';
import styled from 'styled-components';
import StickyMobile from './StickyMobile'

const StyledBurger = styled.div`
  width: 2rem;
  height: 25px;
  position: fixed;
  top: 14px;
  right: 20px;
  z-index: 20;
  display: none;
  @media (min-width: 992px) and (max-width: 1450px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    position: absolute;
    cursor:pointer;
  }
  div {
    width: 24px;
    height: 3px;
    background-color: ${({ open }) => open ? '#ebc11a' : '#ebc11a'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

const StickyBurger = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <StickyMobile open={open} setOpen={setOpen} />
    </>
  )
}

export default StickyBurger;