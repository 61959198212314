import React, { useContext } from 'react';
import ReviewsLeftItem from '../Reviews/ReviewsLeftItem';
import { RatingContext } from "./Reviews";
import RatingLabel from "../../../widgets/RatingLabel"
import { Rating } from '@mui/material';
import Trust from '../../../../../dist/images/singleShop/reviews/trust.png'

export default function ReviewsLeft() {
    const ratingItem = useContext(RatingContext);
    const { ratingCount, rating, ratingNum } = ratingItem;
    return (
        <>
            <div className="reviewsLeft">
                <div className="heading">
                    <Rating
                        value={rating}
                        name="half-rating"
                        precision={0.1}
                        readOnly
                    />
                    <div className="ratingInfo">
                        <div className="left">
                            <span className="count d-inline-block pe-2">{rating}</span>
                            <span className="info"><RatingLabel val={rating} /></span>
                        </div>
                        <div className="right">
                            <span className="count d-inline-block pe-2">{ratingNum}</span>
                            <span className="info">{ratingNum > 1 ? "Ratings" : "Rating"}</span>
                        </div>
                    </div>
                </div>
                <div className="content">
                    <span className="title">Filter By Rating</span>
                    <div className="LeftItemWrap">
                        {
                            ratingCount?.length > 0 &&
                            ratingCount.map((item) => (
                                <ReviewsLeftItem key={(item?.rating_level).toString()} item={item} />
                            ))
                        }
                    </div>
                </div>
                <hr />
                <div className="d-flex bg-light p-4">
                    <div className="icon">
                        <img src={Trust} alt="icon" />
                    </div>
                    <div className="info">
                        <h5>Reviews you can trust</h5>
                        <p>All our ratings are from genuine customers, following verified visits</p>
                    </div>
                </div>
            </div>
        </>
    )
}
