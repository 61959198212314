import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Slider from "react-slick";

//API
import { WEBSITE_VISIT_SLIDER } from "../../../../../gql/Query";

const Feature = () => {

    //--------------------------Banner slider-----------------------
    const imageBaseURL = "https://chuzeday.com/";
    const [slider, setSlider] = useState({})
    const { data, error } = useQuery(WEBSITE_VISIT_SLIDER, {
        variables: {
            type: "website-visit-slider"
        }
    })
    useEffect(() => {
        if (data) {
            setSlider(data?.website_visit_sliders);
        }
        if (error) {
            console.log(error);
        }
    }, [data, error]);

    const settings = {
        //autoplay: true,
        infinite: true,
        speed: 2000,
        //autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return slider?.length > 0 && (
        <section id="Feature">
            <div className="FeatureSlider">
                <Slider {...settings}>
                    {
                        slider?.map((item, i) => (
                            <div className="banerimgWrap" key={i}>
                                <img src={`${imageBaseURL}/${item?.image}`} alt="visit website" />
                                <div className="caption">
                                    <h1>
                                        {item?.title}
                                    </h1>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>
        </section>
    );
}
export default Feature;