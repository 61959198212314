import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from '../../../dist/images/Login/logo.svg';
import arrow from '../../../dist/images/Login/colourLeft.png';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import LoginBottom from "./LoginBottom";
import GetIP from "../widgets/GetIP";
import MetaGenerator from '../widgets/MetaGenerator';

//API
import { SOCIAL_REGISTER, SOCIAL_LOGIN } from '../../../gql/Mutation';

export default function SocialRegister() {
    //phone code from ip address
    const [code, setCode] = useState("IE");
    const userLocation = GetIP();

    const locationData = useLocation();
    const [checked, setChecked] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");
    const [provider, setProvider] = useState("");
    const [providerID, setProviderID] = useState("");

    let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
    }

    const guest_token = localStorage.getItem('guest_token')
    if (guest_token) {
        history.push("/")
    }
    const [socialRegister] = useMutation(SOCIAL_REGISTER, {
        onError(err) {
            if (err.graphQLErrors[0].extensions.validation.mobile) {
                toast.warn(err.graphQLErrors[0].extensions.validation.mobile[0]);
            }
            else if (err.graphQLErrors[0].extensions.validation.email) {
                toast.warn(err.graphQLErrors[0].extensions.validation.email[0]);
            }
        }
    });
    const [socialLogin] = useMutation(SOCIAL_LOGIN, {
        onError(err) {
            if (err.graphQLErrors[0].extensions.validation.mobile) {
                toast.warn(err.graphQLErrors[0].extensions.validation.mobile[0]);
            }
            else if (err.graphQLErrors[0].extensions.validation.email) {
                toast.warn(err.graphQLErrors[0].extensions.validation.email[0]);
            }
        }
    });
    const g_token = localStorage.getItem("g_token");
    const f_token = localStorage.getItem("f_token");
    useEffect(() => {
        if (g_token) {
            if (locationData) {
                // console.log("locationData", locationData);
                setFirstName(locationData.state?.profile?.given_name);
                setLastName(locationData?.state.profile?.family_name);
                setEmail(locationData.state?.profile?.email);
                setProvider("google");
                setProviderID(locationData.state?.profile?.id)
            }
            setPassword(g_token);
            localStorage.removeItem("g_token");
        }
        if (f_token) {
            if (locationData) {
                // console.log("locationData", locationData);
                setFirstName(locationData.state?.profile?.first_name);
                setLastName(locationData?.state.profile?.last_name);
                setEmail(locationData.state?.profile?.email);
                setProvider("facebook");
                setProviderID(locationData.state?.profile?.id)
            }
            setPassword(f_token);
            localStorage.removeItem("f_token");
        }
    }, [locationData, g_token, f_token])
    const focusField = (fieldName) => {
        if (fieldName) {
            document.querySelector(`#${fieldName}`).focus();
            document.querySelector(`#${fieldName}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    const registerSubmit = (e) => {
        e.preventDefault();
        if (!firstName) {
            focusField("firstName");
            toast.error("Please enter first name!");
        } else if (!lastName) {
            focusField("lastName");
            toast.error("Please enter last name!");
        } else if (!checked) {
            focusField("check");
            toast.error("Accept Terms & privacy policy");
        } else {
            socialRegister({
                variables: {
                    first_name: firstName,
                    last_name: lastName,
                    mobile: phone,
                    email: email,
                    password: password,
                    provider: provider,
                    provider_id: providerID
                }
            }).then(({ data }) => {
                // console.log(data.clientRegister.message);
                if (data) {
                    toast.success("Registration successful!");
                    socialLogin({
                        variables: {
                            provider: providerID,
                            token: password,
                        }
                    }).then(({ data }) => {
                        // you can do something with the response here
                        console.log(data);
                        if (data) {
                            // toast.success("Login successful!");
                            if (data.login) {
                                localStorage.setItem('guest_token', data.socialLogin.access_token);
                                localStorage.setItem('cz_type', data.socialLogin.user.user_type);
                            }
                            history.push('/profile/my-profile')
                        }
                    }).catch(e => {
                        console.log(JSON.stringify(e));
                    })
                }
            }).catch(e => {
                console.log(e);
            })
        }

    }
    useEffect(() => {
        if (userLocation) {
            setCode(userLocation.country_code)
        }
    }, [userLocation])
    return (
        <>
            <MetaGenerator
                title="Sign up with Facebook || Google"
            />
            <section id="SignUp">
                <div className="mailLoginWrap">
                    <div className="LoginInner">
                        <div className="LogoWrap">
                            <span onClick={goToPreviousPath} className="BackHome">
                                <img src={arrow} alt="icon" />
                            </span>
                            <Link to="/home" className="Logo">
                                <img src={Logo} alt="Logo" />
                            </Link>
                            <span></span>
                        </div>
                        <div className="LoginTop">
                            <span className="title">Sign Up to Book</span>
                            <Form className="signUpForm" onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    registerSubmit();
                                }
                            }} autocomplete="off">
                                <div className="formInput">
                                    <Row>
                                        <Form.Group as={Col} md={6} className="pe-md-3 mb-3">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text" id="firstName" value={firstName} placeholder="Your First Name" onChange={(e) => { setFirstName(e.target.value) }} />
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="ps-md-3 mb-3">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text" id="lastName" placeholder="Your Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md={6} className="mb-3 pe-sm-3">
                                            <Form.Label htmlFor="inlineFormInputGroup">
                                                Mobile Number
                                            </Form.Label>
                                            <InputGroup className="Number d-block">
                                                <PhoneInput
                                                    defaultCountry={code}
                                                    placeholder="Enter phone number"
                                                    value={phone}
                                                    onChange={setPhone}
                                                    id="phone"
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} className="mb-3 ps-md-3">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" placeholder="Your Email Address" readOnly value={email} onChange={(e) => { setEmail(e.target.value) }} />
                                        </Form.Group>
                                    </Row>
                                    <div className="checkCondition">
                                        <Form.Group className="mb-3" id="formGridCheckbox">
                                            <Form.Check type="checkbox" id="check" onChange={() => setChecked(!checked)} />
                                        </Form.Group>
                                        <span>I agree to the <Link to="/">privacy policy</Link>,<Link to="/"> website terms</Link> and <Link to="/"> booking terms</Link></span>
                                    </div>
                                </div>
                                <Button className="signUpBtn" onClick={registerSubmit}>
                                    Sign Up
                                </Button>
                            </Form>
                            <div className="register">
                                <span>Already have a account?</span>
                                <Link to="/login" className="RegisterBtn">
                                    Log In Now
                                </Link>
                            </div>
                        </div>
                        <LoginBottom />
                    </div>
                </div>
            </section>
        </>
    )
}
