import React from "react";
const CountStar = ({ val }) => {
    var stars = []
    var x
    for (x = 0; x < val; x++) {
        stars.push(x)
    }
    return (
        <>
            {
                stars &&
                stars.map((x) => (
                    <li key={x}><i className="fa fa-star"></i></li>
                ))
            }
        </>
    )
}
export default CountStar