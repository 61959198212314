import React, { useContext, useRef } from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import { RatingContext } from "./Reviews";
import CountStar from "../../../widgets/CountStar"

export default function ReviewsLeftItem({ item }) {
    const ratingItem = useContext(RatingContext);
    const { ratingList, setRatingList, ratingNum } = ratingItem;
    //console.log("ratingList", ratingList);
    const filterRating = useRef(ratingList);
    // console.log(filterRating)
    const sortRating = (val) => {
        setRatingList(filterRating?.current?.filter((item) => item?.rating === val))
    }
    return (
        <>
            <div className="Item Item1" key={item.rating_level}>
                <Form.Group>
                    <input type="radio" id="five" name="rating" onChange={() => { sortRating(item.rating_level); }} />
                </Form.Group>
                <ul className="starWrap">
                    <CountStar val={item.rating_level} />
                </ul>
                <div className="progressWrap">
                    <ProgressBar now={(item?.number_of_rating / ratingNum * 100).toFixed(2)} />
                </div>
                <div className="count">
                    <span>{item?.number_of_rating > 0 ? item?.number_of_rating : ""}</span>
                </div>
            </div>
        </>
    )
}
