import React, { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import Logo from '../../../dist/images/logo.svg'
import { Navbar, Nav } from 'react-bootstrap';
import StickySearch from './StickySearch';
import AuthHeader from "./AuthHeader";
import ScrollTop from "../widgets/ScrollTop";
import SmHeader from './SmHeader';

export default function FixHeader() {
    const { shop_slug } = useParams();
    const [guest_token, setGuest_token] = useState();
    const prt_token = localStorage.getItem("token");
    useEffect(() => {
        let intervalId;

        if (shop_slug) {
            intervalId = setInterval(() => {
                setGuest_token(localStorage.getItem("guest_token"));
            }, 1000);
        } else {
            setGuest_token(localStorage.getItem("guest_token"));
        }

        // Clean-up function
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [shop_slug]);

    return (
        <>
            <ScrollTop />
            <div id="fix-header">
                <header className="d-block d-lg-none mobile-header" id="header">
                    <SmHeader />
                </header>
                <Navbar expand="lg" className="navbar active">
                    <NavLink to="/home" className="logo">
                        <img src={Logo} alt="Logo-img" />
                    </NavLink>
                    <StickySearch className="sticky-search" />
                    <Nav
                        className="ms-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                        <ul className="primaryMenu">
                            {/* <li><NavLink exact className="navItem" to="/">Home</NavLink></li> */}
                            {/* <li><NavLink exact className="navItem" to="/system/about">About</NavLink></li> */}
                            <li><NavLink exact className="navItem" to="/system/blog">Blog</NavLink></li>
                            {/* <li><NavLink exact className="navItem" to="/system/contact-us">Contact</NavLink></li> */}
                            <li><NavLink exact className="navItem" to="/for-business">For Business</NavLink></li>
                            <li><NavLink exact className="navItem" to="/for-guest">For Guest</NavLink></li>
                            {
                                !guest_token &&
                                <li className="dropdownWrap"><NavLink className="navItem" to="/">Log In</NavLink>
                                    <ul className="submenu">
                                        <li><a href={prt_token ? "https://business.chuzeday.com/home" : "https://business.chuzeday.com/auth/login"} target="_blank" rel="noreferrer">Business</a></li>
                                        <li><NavLink to="/guest-login">Guests</NavLink></li>
                                    </ul>
                                </li>
                            }
                        </ul>
                        {guest_token &&
                            <AuthHeader />
                        }
                    </Nav>
                </Navbar>
            </div>
        </>
    )
}
